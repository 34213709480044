export const categoryData = [
  {
    id: "1",
    name: "Mode et Accessoires",
    img: "img/categories/fashion.jpg",
    to: "Fashion",
    associatedIcon: "tshirt",
    shortName: "mode",
  },
  {
    id: "2",
    name: "Épicerie",
    img: "img/categories/grocery.jpg",
    to: "Grocery",
    associatedIcon: "shopping-bag",
    shortName: "epicerie",
  },
  {
    id: "3",
    name: "Bien-être et Santé",
    img: "img/categories/wellness.jpg",
    to: "Wellness",
    associatedIcon: "spa",
    shortName: "sante",
  },
  // {
  //   id: '4',
  //   name: 'Bières',
  //   img: require('./../../assets/images/beer.jpg'),
  //   to: 'Beers',
  //   associatedIcon: 'beer',
  //   shortName: 'bieres',
  // },
  // {
  //   id: '5',
  //   name: 'Vins et Spiritueux',
  //   img: require('./../../assets/images/wine.jpg'),
  //   to: 'Wine',
  //   associatedIcon: 'wine-glass-alt',
  //   shortName: 'vins',
  // },
  {
    id: "6",
    name: "Sports",
    img: "img/categories/sports.jpg",
    to: "Sports",
    associatedIcon: "shuttlecock",
    shortName: "sports",
  },
  {
    id: "7",
    name: "Loisirs",
    img: "img/categories/creative.jpg",
    to: "Creative",
    associatedIcon: "pencil-paintbrush",
    shortName: "creative",
  },

  {
    id: "8",
    name: "High-Tech",
    img: "img/categories/highTech.jpg",
    to: "High-Tech",
    associatedIcon: "phone-laptop",
    shortName: "hightech",
  },
  {
    id: "9",
    name: "Univers Bébé",
    img: "img/categories/baby.jpg",
    to: "Baby",
    associatedIcon: "baby",
    shortName: "bébé",
  },
  {
    id: "10",
    name: "Maison",
    img: "img/categories/home.jpg",
    to: "Home",
    associatedIcon: "home-heart",
    shortName: "maison",
  },
];

export const getCategoryName = (route) => {
  const selectedCategory = categoryData.filter((e) => e.to === route);
  return selectedCategory[0].name;
};
