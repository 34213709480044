import React from "react";
import styled from "styled-components";
import { categoryData } from "../../../utils/categoryUtil";

const SelectInput = styled.select`
  padding: 10px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 5px;
`;

const Label = styled.label`
  margin-top: 5px;
`;

const ErrorSpan = styled.span`
  font-size: 12px;
  display: block;
  color: ${(props) => props.theme.lightRed};
  font-style: italic;
`;

function Select({ label, setFieldValue, name, value, error }) {
  return (
    <>
      {label && <Label>{label}</Label>}
      <SelectInput
        onChange={(e) => setFieldValue(name, e.target.value)}
        value={value}
      >
        <option value="" key="0">
          - Choisir une catégorie -
        </option>
        {categoryData &&
          categoryData.map((e) => (
            <option value={e.shortName} key={e.id}>
              {e.name}
            </option>
          ))}
      </SelectInput>
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </>
  );
}

export default Select;
