import { ADD_TOASTR, REMOVE_TOASTR } from "./toastrConstants";

export const addToastrAction = (toastr) => (dispatch, getState) => {
  const toastrId = Math.floor(Math.random() * 1000000).toString();
  dispatch({ type: ADD_TOASTR, payload: { ...toastr, toastrId } });
  if (toastr.type === "success") {
    setTimeout(() => dispatch(removeToastrAction(toastrId)), 3000);
  } else {
    setTimeout(() => dispatch(removeToastrAction(toastrId)), 7000);
  }
};

export const removeToastrAction = (toastrId) => (dispatch, getState) => {
  dispatch({ type: REMOVE_TOASTR, payload: toastrId });
};
