import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: auto;
`;

function ImageViewerModal({ src }) {
  const [selectedImage, setSelectedImage] = useState(0);
  useEffect(() => {}, []);
  if (typeof src === "string") {
    return <Image src={src} />;
  } else return null;
}

export default ImageViewerModal;
