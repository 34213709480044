import React from "react";
import styled from "styled-components";

const OrderProductContainer = styled.div`
  display: flex;
  width: 50%;
  min-width: 550px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 7px;
`;

const OrderProductImage = styled.img`
  margin-right: 40px;
`;

const OrderProductInfo = styled.div`
  flex: 10;
`;

const OrderProductQuantity = styled.div`
  font-weight: 500;
  flex: 1;
`;

const OrderProductItem = ({ product }) => {
  return (
    <OrderProductContainer>
      <OrderProductImage src={product.mainImage} width="70" height="70" />
      <OrderProductInfo>
        {product.name} {product.variant ? `- ${product.variant}` : null}
      </OrderProductInfo>
      <OrderProductQuantity>x {product.orderedQuantity}</OrderProductQuantity>
    </OrderProductContainer>
  );
};

export default OrderProductItem;
