import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { logOutAction } from "../../redux/auth/authActions";
import { db, firebase } from "./../../utils/fbUtil";
import { getConversationsAction } from "../../redux/messenger/messengerActions";
import { openModalAction } from "../../redux/modal/modalActions";
import EditProProfileModal from "../modals/EditProProfileModal";

const Div = styled.div`
  position: fixed;
  width: 100%;
  margin: 0;
  height: 70px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.marineBlue};
`;

const Logo = styled.img`
  float: left;
  height: 50px;
`;

const Title = styled.h1`
  float: left;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 30px;
  font-weight: 300;
  line-height: 50px;
  margin-left: 12px;
  font-family: "BalletHarmony";
  @media (max-width: 1000px) {
    display: none;
  }
`;

const NavigationMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

const MenuItem = styled(NavLink)`
  padding: 10px;
  margin: 10px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid #ffffff;
    margin-top: 12px;
  }
  &.active {
    border-bottom: 2px solid #ffffff;
    margin-top: 12px;
    font-weight: 600;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const MenuIcon = styled.i`
  font-size: 24px;
  coor: #ffffff;
`;

const ShopImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.4);
  &:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
`;

const DropDown = styled.div`
  position: absolute;
  display: block;
  z-index: 10;
  top: 40px;
  right: 62.5px;
`;

const DropIcon = styled.i`
  color: #0091ff;
  font-size: 20px;
`;

const DropMenuItem = styled(NavLink)`
  min-width: 150px;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  padding: 20px;
  &:hover {
    filter: brightness(0.8);
  }
`;

const LogoutButton = styled.div`
  color: #ffffff;
  font-size: 15px;
  padding: 20px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

const DropMenuDiv = styled.div`
  background-color: #042a5f;
  border-radius: 10px;
  display: flex;
  position: absolute;
  right: 0px;
  top: 70px;
  z-index: 20;
  text-align: center;
  flex-direction: column;
`;

const Menu = ({
  user,
  isLoggedIn,
  logOutAction,
  history,
  getConversationsAction,
  openModalAction,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const modal = {
    modalTitle: "Modifier le profil",
    modalType: "edit-pro-profile",
  };
  return (
    <React.Fragment>
      <Div>
        <div>
          <Logo src="/img/logo-blanc.png" />
          <Title>La Chariotte</Title>
        </div>
        <NavigationMenu>
          {isLoggedIn === false ? (
            <React.Fragment>
              <MenuItem to="/enregistrement">Créer un compte</MenuItem>
              <MenuItem to="/connexion">Se Connecter</MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MenuItem exact to="/accueil" title="Accueil">
                <MenuIcon className="fal fa-home-alt" />
              </MenuItem>
              <MenuItem exact to="/commandes" title="Commandes">
                <MenuIcon className="fal fa-box" />
              </MenuItem>
              <MenuItem to="/produits" title="Produits">
                <MenuIcon className="fal fa-box-full" />
              </MenuItem>
              <MenuItem to="/promotions" title="Promotions">
                <MenuIcon className="fal fa-percent" />
              </MenuItem>
              <MenuItem to="/messagerie" title="Messagerie">
                <MenuIcon className="fal fa-comments" />
              </MenuItem>
            </React.Fragment>
          )}

          {window.innerWidth > 600 && (
            <ShopImage onClick={() => setDropDown(!dropDown)}>
              <img
                style={{ height: 40, borderRadius: 20 }}
                alt="Boutique"
                src={
                  user && user.imageUrl
                    ? user.imageUrl
                    : "https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/blank-avatar.png?alt=media"
                }
              />
              {isLoggedIn && (
                <div
                  style={{
                    position: "absolute",
                    width: 20,
                    right: 56.5,
                    top: 40,
                    border: 1,
                  }}
                >
                  <DropIcon className="fa fa-sort-down" />
                </div>
              )}
            </ShopImage>
          )}
        </NavigationMenu>
      </Div>
      {dropDown && isLoggedIn && (
        <DropMenuDiv>
          <DropMenuItem
            title="Ma boutique"
            style={{ backgroundColor: "#165DBF" }}
            to="/boutique"
            onClick={() => {
              setDropDown(false);
            }}
          >
            <MenuIcon className="fal fa-store" />
          </DropMenuItem>
          <DropMenuItem
            title="Modifier le profil"
            to=""
            style={{ backgroundColor: "#042a5f" }}
            onClick={() => {
              openModalAction(modal);
              setDropDown(false);
            }}
          >
            <MenuIcon className="fal fa-edit" />
          </DropMenuItem>
          <LogoutButton
            title="Se déconnecter"
            onClick={() => {
              logOutAction();
              setDropDown(false);
            }}
            style={{ backgroundColor: "#C42F3C" }}
            to=""
          >
            <MenuIcon className="fal fa-sign-out-alt" />
          </LogoutButton>
        </DropMenuDiv>
      )}
    </React.Fragment>
  );
};

// Menu.propTypes = {
//   props: PropTypes,
// };

const mapState = (state, props) => ({
  user: state.user,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapActions = {
  logOutAction,
  getConversationsAction,
  openModalAction,
};

export default connect(mapState, mapActions)(Menu);
