import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  closeModalAction,
  goBackModalAction,
} from "../../redux/modal/modalActions";
import ProductForm from "./ProductForm";
import ResetPasswordModal from "./ResetPasswordModal";
import EditProProfileModal from "./EditProProfileModal";
import ProductModal from "./ProductModal";
import ChangeEmailModal from "./ChangeEmailModal";

import TermsAndConditions from "./TermsAndConditions";
import ImageViewerModal from "./ImageViewerModal";
import PromotionModal from "./PromotionModal";
import PromotionForm from "./PromotionForm";
import CancelOrderModal from "./proOrders/CancelOrderModal";
import ValidateOrderModal from "./proOrders/ValidateOrderModal";
import ShipModal from "./proOrders/ShipModal";
import NewMessageModal from "./proOrders/NewMessageModal";

const OutsideContainer = styled.div`
  position: fixed;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
`;

const InsideContainer = styled.div`
  position: relative;
  border-radius: 7px;
  overflow-y: auto;
  display: block;
  min-height: 500px;
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  min-width: 40%;
  background-color: #fff;
  padding: 40px;
  animation: fadein 0.4s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 20px;
  width: 100%;
  ${"" /* text-align: center; */}
  font-size: 18px;
  font-weight: 700;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  ${"" /* min-height: 100%; */}
`;

const Icon = styled.i`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(155, 155, 155, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.lightRed};
    i {
      color: #fff;
    }
  }
`;

const PreviousButton = styled.div`
  position: absolute;
  top: 20px;
  right: 80px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(155, 155, 155, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.franceBlue};
    i {
      color: #fff;
    }
  }
`;

const Modal = ({
  modalTitle,
  goBackModalAction,
  closeModalAction,
  modalType,
  modalStack,
  modalContent,
  confirmAction,
}) => {
  return (
    <OutsideContainer>
      <InsideContainer>
        {modalType !== "terms" && (
          <>
            <CloseButton onClick={() => closeModalAction()}>
              <Icon size="20px" color="#C42F3C" className="fal fa-times" />
            </CloseButton>
            {modalStack && modalStack.length > 0 && (
              <PreviousButton onClick={() => goBackModalAction()}>
                <Icon
                  size="15px"
                  color="#165DBF"
                  className="far fa-chevron-left"
                />
              </PreviousButton>
            )}
          </>
        )}

        {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
        <ModalContainer>
          {modalType === "product" && <ProductModal product={modalContent} />}
          {modalType === "add-product" && (
            <ProductForm product={modalContent} />
          )}
          {modalType === "edit-pro-profile" && <EditProProfileModal />}
          {modalType === "reset-password" && (
            <ResetPasswordModal email={modalContent} />
          )}
          {modalType === "terms" && <TermsAndConditions />}
          {modalType === "change-email" && (
            <ChangeEmailModal updatedUser={modalContent} />
          )}
          {modalType === "image-viewer" && (
            <ImageViewerModal src={modalContent} />
          )}
          {modalType === "promotion" && (
            <PromotionModal promotion={modalContent} />
          )}
          {modalType === "add-promotion" && <PromotionForm />}
          {modalType === "cancel-pro-order" && (
            <CancelOrderModal subOrderId={modalContent} />
          )}
          {modalType === "validate-pro-order" && (
            <ValidateOrderModal subOrderId={modalContent} />
          )}
          {modalType === "ship-pro-order" && (
            <ShipModal subOrderId={modalContent} />
          )}
          {modalType === "new-message" && (
            <NewMessageModal userId={modalContent} />
          )}
        </ModalContainer>
      </InsideContainer>
    </OutsideContainer>
  );
};

const mapState = (state, props) => ({
  modalContent: state.modal.modalContent,
  modalTitle: state.modal.modalTitle,
  modalType: state.modal.modalType,
  modalStack: state.modal.modalStack,
  confirmAction: state.modal.confirmAction,
});

const mapActions = {
  closeModalAction,
  goBackModalAction,
};

export default connect(mapState, mapActions)(Modal);
