import { Formik, Form } from "formik";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  closeModalAction,
  addStackModalAction,
} from "../../redux/modal/modalActions";
import { updateUserAction } from "../../redux/user/userActions";
import ImageInput from "../components/Form/ImageInput";
import TextArea from "../components/Form/TextArea";
import TextInput from "../components/Form/TextInput";

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.green};
  color: #fff;
  width: 100%;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 30px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
  &:hover {
    filter: brightness(0.8);
  }
`;

function EditProProfileModal({
  user,
  updateUserAction,
  closeModalAction,
  addStackModalAction,
}) {
  return (
    <Formik
      initialValues={
        user
          ? { ...user }
          : {
              shippingOptions: [{}],
            }
      }
      onSubmit={(values) => {
        if (values !== user) {
          updateUserAction(values);
          closeModalAction();
        }
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <Form>
          <Column
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              paddingBottom: 20,
            }}
          >
            <ImageInput
              name="imageUrl"
              value={values.imageUrl}
              imageSize="230px"
              setFieldValue={setFieldValue}
              round={true}
            />
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Nom de l'entreprise"
                value={values.name}
                name="name"
                onChange={handleChange}
              />
            </Row>

            <Row style={{ width: "100%" }}>
              <TextArea
                label="Description"
                value={values.description}
                name="description"
                onChange={handleChange}
              />
            </Row>

            <Row style={{ width: "100%" }}>
              <TextInput
                label="Adresse"
                value={values.address}
                name="address"
                onChange={handleChange}
              />
            </Row>

            <Row style={{ width: "100%" }}>
              <TextInput
                label="Code Postal"
                value={values.postalCode}
                name="postalCode"
                onChange={handleChange}
                type="number"
              />
              <TextInput
                label="Ville"
                value={values.city}
                name="city"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Adresse e-mail"
                value={values.email}
                name="email"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Numéro de téléphone"
                value={values.phoneNumber}
                name="phoneNumber"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Montant minimum pour la livraison (€)"
                placeholder="Ex : 10"
                value={values.minShipAmount}
                name="minShipAmount"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Montant minimum pour la livraison gratuite (€)"
                placeholder="Ex : 20"
                value={values.minFreeShipAmount}
                name="minFreeShipAmount"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Mode de livraison"
                placeholder="Ex: Colissimo"
                value={values.shippingOptions[0].name}
                name="shippingOptions[0].name"
                onChange={handleChange}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <TextInput
                label="Coût de la livraison (€)"
                placeholder="Ex : 2"
                value={values.shippingOptions[0].price}
                name="shippingOptions[0].price"
                onChange={handleChange}
              />
            </Row>
          </Column>
          <SubmitButton
            onClick={(e) => {
              e.preventDefault();
              if (values.email !== user.email) {
                addStackModalAction({
                  modalTitle: "Modification E-mail",
                  modalType: "change-email",
                  modalContent: values,
                });
              } else {
                handleSubmit();
              }
            }}
          >
            <i className="fal fa-check" />
            Enregistrer
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

const mapState = (state, props) => ({
  user: state.user,
});

const mapActions = {
  updateUserAction,
  closeModalAction,
  addStackModalAction,
};

export default connect(mapState, mapActions)(EditProProfileModal);
