import { createReducer } from "./../../utils/reducerUtil";
import {
  GET_PRO_ORDERS,
  SET_FILTER,
  SET_PRO_ORDER_STATUS,
} from "./proOrdersConstants";

// TO REMOVE LATER
import { toTimestamp } from "./../../utils/dateUtil";

const initialState = {
  filterType: null,
};

export const setOrdersFilter = (state, payload) => {
  let newOrders = [];
  if (payload === "all") {
    newOrders = state.ordersList;
  } else if (payload === "active") {
    newOrders = state.ordersList.filter((e) => {
      if (
        e.status === 1 ||
        e.status === 2 ||
        e.status === 3 ||
        e.status === 5 ||
        e.status === 7 ||
        e.status === 9 ||
        e.status === 11
      ) {
        return e;
      }
    });
  } else if (payload === "valid") {
    newOrders = state.ordersList.filter((e) => e.status === 2);
  } else if (payload === "dispute") {
    newOrders = state.ordersList.filter((e) => e.status === 7);
  } else if (payload === "past") {
    newOrders = state.ordersList.filter((e) => {
      if (e.status === 4 || e.status === 6) {
        return e;
      }
    });
  }

  return {
    ...state,
    filterType: payload,
    displayedOrders: newOrders,
  };
};

export const getProOrders = (state, payload) => {
  const ordersList = payload.map((e) => e.subOrder);
  return {
    ...state,
    ordersList: [...ordersList],
    displayedOrders: [...ordersList],
    filterType: null,
    parentOrders: payload,
  };
};

export const setProOrderStatus = (state, payload) => {
  return {
    ...state,
    ordersList: state.ordersList.map((e) => {
      if (e.subOrderId === payload) {
        e.status = payload.status;
      }
      return e;
    }),
    displayedOrders: state.displayedOrders.map((e) => {
      if (e.subOrderId === payload) {
        e.status = payload.status;
      }
      return e;
    }),
    parentOrders: state.parentOrders.map((e) => {
      if (e.orderId === payload.parentOrderId) {
        e.subOrder.status = payload.status;
      }
      return e;
    }),
  };
};

export default createReducer(initialState, {
  [SET_FILTER]: setOrdersFilter,
  [GET_PRO_ORDERS]: getProOrders,
  [SET_PRO_ORDER_STATUS]: setProOrderStatus,
});

const init = {
  filterType: null,
};
