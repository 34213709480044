import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { addStackModalAction } from "../../redux/modal/modalActions";
import { removeproductAction } from "../../redux/pro/proActions";
import { dateUtil } from "../../utils/dateUtil";

const Container = styled.div`
  padding-top: 10px;
  height: 100%;
  backgrround-color: #ffff;
  width: 100%;
`;

const Carousel = styled.div`
  display: flex;
  height: 300px;
  flex-direction: row;
  align-items: center;
  max-width: 600px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
`;

const Selector = styled.div``;

const Icon = styled.i`
  margin-right: ${(props) => (props.button ? "10px" : 0)};
`;

const Image = styled.img`
  display: inline-block;
  height: 300px;
  margin: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const Description = styled.div`
  margin-top: 10px;
`;

const Name = styled.div`
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
`;

const Price = styled.div`
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
`;

const PriceNb = styled.span`
  text-decoration: ${(props) => (props.overLine ? "line-through" : null)};
`;

const NewPrice = styled.span`
  color: ${(props) => props.theme.darkOrange};
  margin-left: 10px;
`;

const PrepDelay = styled.div`
  font-size: 15px;
  font-style: italic;
  margin-top: 3px;
  color: ${(props) => props.theme.lightGrey};
  margin-right: ${(props) => (props.variant ? "5px" : 0)};
`;

const Details = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
`;

const VariantsContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
`;

const EditButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.blue};
  color: ${(props) => props.theme.blue};
  margin-top: 15px;
  margin-right: 15px;
  outline: 0;
  background-color: #fff;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.blue};
    color: #fff;
  }
`;

const RemoveButton = styled.button`
  padding: 12px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lightRed};
  color: #ffffff;
  font-weight: 500;
  margin-top: 15px;
  border: none;
  outline: 0;
  background-color: ${(props) => props.theme.lightRed};
  font-size: 17px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

const PromotionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border: ${(props) => `1px solid ${props.theme.darkOrange}`};
  border-radius: 5px;
`;

const PromotionIcon = styled.i`
  color: ${(props) => props.theme.darkOrange};
  font-size: 20px;
  margin-right: 10px;
`;

const PromotionText = styled.span`
  color: ${(props) => props.theme.darkOrange};
  font-size: 18px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

function ProductModal({ product, addStackModalAction, removeproductAction }) {
  const [promo, setPromotion] = useState(false);
  useEffect(() => {
    const todaysDate = new Date();
    if (product.promotion) {
      const startDate = dateUtil(product.promotion.startDate);
      const endDate = dateUtil(product.promotion.endDate);
      if (startDate <= todaysDate && endDate >= todaysDate) {
        setPromotion(true);
      }
    }
  }, []);
  const modal = {
    modalTitle: "Modifier le produit",
    modalType: "add-product",
    modalContent: product,
  };
  const onImageClick = (imageSelected) => {
    addStackModalAction({
      modalType: "image-viewer",
      modalTitle: "Aperçu d'image",
      modalContent: imageSelected,
    });
  };
  return (
    <Container>
      <Carousel>
        {product.mainImage && (
          <Image
            onClick={() => onImageClick(product.mainImage)}
            src={product.mainImage}
          />
        )}
        {product.secondImage && (
          <Image
            onClick={() => onImageClick(product.secondImage)}
            src={product.secondImage}
          />
        )}
        {product.thirdImage && (
          <Image
            onClick={() => onImageClick(product.thirdImage)}
            src={product.thirdImage}
          />
        )}
        {product.fourthImage && (
          <Image
            onClick={() => onImageClick(product.fourthImage)}
            src={product.fourthImage}
          />
        )}
      </Carousel>
      <Name>
        {product.name} - {product.quantity}
      </Name>
      <Price>
        Prix :{" "}
        <PriceNb overLine={promo}>
          {product.unitPrice.toLocaleString("fr-FR")} €
        </PriceNb>
        {promo && (
          <NewPrice>
            {(product.unitPrice * (1 - product.promotion.percentage / 100))
              .toFixed(2)
              .replace(".", ",")}{" "}
            €
          </NewPrice>
        )}
      </Price>
      {promo && (
        <PromotionContainer>
          <PromotionIcon className="fal fa-tag" />
          <PromotionText>
            {product.promotion.name} : -{product.promotion.percentage}%
          </PromotionText>
        </PromotionContainer>
      )}
      <Description>{product.description}</Description>
      <PrepDelay>
        Délai de préparation : {product.preparation}{" "}
        {product.preparation === 1 ? "jour" : "jours"}
      </PrepDelay>
      <RowContainer>
        <Details>
          Stock : {product.stock}&nbsp;&nbsp;/&nbsp;&nbsp;Vendus :{" "}
          {product.sold}
        </Details>
      </RowContainer>

      {product.variants && product.variants.length > 1 && (
        <>
          <Details>Variantes disponibles :</Details>
          <VariantsContainer>
            {product.variants.map((e, i) => {
              if (e.name) {
                if (e.stock > 0) {
                  if (i !== product.variants.length - 1) {
                    return (
                      <PrepDelay key={i} variant>
                        {e.name}&nbsp;&nbsp;/
                      </PrepDelay>
                    );
                  } else {
                    {
                      /* TODO : Add line-through if variant has 0 stock */
                    }
                    return (
                      <PrepDelay key={i} variant>
                        {e.name}
                      </PrepDelay>
                    );
                  }
                }
              } else {
                if (i !== product.variants.length - 1) {
                  return (
                    <PrepDelay key={i} variant>
                      {e}&nbsp;&nbsp;/
                    </PrepDelay>
                  );
                } else {
                  return (
                    <PrepDelay key={i} variant>
                      {e}
                    </PrepDelay>
                  );
                }
              }
            })}
          </VariantsContainer>
        </>
      )}
      <RowContainer>
        <EditButton onClick={() => addStackModalAction(modal)}>
          <Icon button className="far fa-edit" />
          MODIFIER LE PRODUIT
        </EditButton>
        <br />
        <RemoveButton
          onClick={() => {
            // if (
            //   window.confirm(
            //     `Êtes-vous certain de vouloir supprimer ce produit (${product.name}) ?`
            //   )
            // ) {
            //   removeproductAction(product.productId);
            // }
            alert("Pour supprimer un produit, veuillez mettre les stocks à 0.");
          }}
        >
          <Icon button className="far fa-trash-alt" />
          SUPPRIMER LE PRODUIT
        </RemoveButton>
      </RowContainer>
    </Container>
  );
}

const mapState = (state, props) => ({});

const mapActions = {
  addStackModalAction,
  removeproductAction,
};

export default connect(mapState, mapActions)(ProductModal);
