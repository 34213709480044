import { createReducer } from "./../../utils/reducerUtil";
import { toTimestamp } from "./../../utils/dateUtil";
import {
  GET_CONVERSATION,
  GET_CONVERSATIONS,
  SEND_MESSAGE,
  NEW_CONVERSATION_STARTED,
  SEND_MESAGE_OUTSIDE,
  RESET_CONVERSATIONS,
} from "./messengerConstants";

const initialState = {};

export const sendMessageFromOutsideMessenger = (state, payload) => {
  return {
    ...state,
    conversations: [
      payload.newConversation,
      ...state.conversations.filter(
        (e) => e.conversationId !== payload.conversationId
      ),
    ],
  };
};

export const sendMessage = (state, payload) => {
  return {
    ...state,
    messages: [
      ...state.messages,
      { ...payload.message, createdAt: toTimestamp(new Date()) },
    ],
    conversations: [
      payload.newConversation,
      ...state.conversations.filter(
        (e) => e.conversationId !== payload.conversationId
      ),
    ],
  };
};

export const getConversations = (state, payload) => {
  return { ...state, conversations: payload };
};

export const getConversation = (state, payload) => {
  return { ...state, messages: payload };
};

export const newConversationStarted = (state, payload) => {
  const newConversations = [...state.conversations, payload];
  return { ...state, conversations: newConversations };
};

export const resetConversations = (state, payload) => {
  return { ...state, conversations: [] };
};

export default createReducer(initialState, {
  [SEND_MESSAGE]: sendMessage,
  [GET_CONVERSATIONS]: getConversations,
  [GET_CONVERSATION]: getConversation,
  [NEW_CONVERSATION_STARTED]: newConversationStarted,
  [SEND_MESAGE_OUTSIDE]: sendMessageFromOutsideMessenger,
  [RESET_CONVERSATIONS]: resetConversations,
});
