import React from "react";
import styled from "styled-components";
import { toOrderNumber } from "../../utils/ordersUtil";

const Container = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
`;

const OrderNumber = styled.div`
  font-weight: 600;
`;

const Price = styled.div`
  color: ${(props) =>
    props.positive ? props.theme.green : props.theme.lightRed};
`;

const SalesItem = ({ order }) => {
  const pricePositive = order.price && order.price > 0;
  const priceNegative = order.price && order.price < 0;
  return (
    <Container>
      <OrderNumber>Commande {toOrderNumber(order.orderNumber)}</OrderNumber>
      <Price positive={pricePositive}>
        {pricePositive
          ? `+ ${parseFloat(order.price).toFixed(2).replace(".", ",")} €`
          : null}
        {priceNegative
          ? `- ${parseFloat(order.price).toFixed(2).replace(".", ",")} €`
          : null}
        {!pricePositive && !priceNegative && "0,00 €"}
      </Price>
    </Container>
  );
};

export default SalesItem;
