import {
  SET_PRODUCT,
  SET_PROMOTION,
  GET_PRODUCTS,
  GET_PROMOTIONS,
  REMOVE_PROMOTION,
  UPDATE_PRODUCT,
  GET_BUYER_BASIC_INFO,
  REMOVE_PRODUCT,
} from "./proConstants";
import { db, firebase } from "./../../utils/fbUtil";
import { addToastrAction } from "../toastr/toastrActions";
import { dateUtil, toTimestamp } from "../../utils/dateUtil";
import { closeModalAction } from "../modal/modalActions";

export const getBuyerBasicInfoAction =
  (userId) => async (dispatch, getState) => {
    try {
      const userSnapshot = await db.collection("users").doc(userId).get();
      const userTwoInfo = { ...userSnapshot.data() };
      const userTwoBasic = {
        firstName: userTwoInfo.firstName,
        lastName: userTwoInfo.lastName,
        email: userTwoInfo.email,
        phoneNumber: userTwoInfo.phoneNumber,
      };
      dispatch({ type: GET_BUYER_BASIC_INFO, payload: userTwoBasic });
    } catch (error) {
      // console.error(error);
    }
  };

export const getProductsAction = () => async (dispatch, getState) => {
  try {
    let products = [];
    const userId = firebase.auth().currentUser.uid;
    // console.log("userId => ", userId);
    const productsSnapshot = await db
      .collection("products")
      .where("sellerId", "==", userId)
      .get();
    productsSnapshot.forEach((doc) =>
      products.push({ ...doc.data(), productId: doc.id })
    );
    // console.log(products);
    dispatch({ type: GET_PRODUCTS, payload: products });
  } catch (error) {
    // console.error(error);
  }
};

export const setProductAction =
  (product, productId) => async (dispatch, getState) => {
    // console.log("setProductAction", { product, productId });
    if (productId) {
      try {
        const userId = firebase.auth().currentUser.uid;
        console.log("updateProduct action started");
        let mainImage = null;
        let secondImage = null;
        let thirdImage = null;
        let fourthImage = null;
        if (
          typeof product.mainImage !== "string" ||
          !product.mainImage.startsWith("https://")
        ) {
          const mainImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${mainImageId}`)
            .put(product.mainImage);
          mainImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${mainImageId}?alt=media`;
        }
        if (
          product.secondImage &&
          (typeof product.secondImage !== "string" ||
            !product.secondImage.startsWith("https://"))
        ) {
          const secondImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${secondImageId}`)
            .put(product.secondImage);
          secondImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${secondImageId}?alt=media`;
        }
        if (
          product.thirdImage &&
          (typeof product.thirdImage !== "string" ||
            !product.thirdImage.startsWith("https://"))
        ) {
          const thirdImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${thirdImageId}`)
            .put(product.thirdImage);
          thirdImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${thirdImageId}?alt=media`;
        }
        if (
          product.fourthImage &&
          (typeof product.fourthImage !== "string" ||
            !product.fourthImage.startsWith("https://"))
        ) {
          const fourthImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${fourthImageId}`)
            .put(product.fourthImage);
          fourthImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${fourthImageId}?alt=media`;
        }

        const unitPrice =
          typeof product.unitPrice === "string"
            ? product.unitPrice.replace(",", ".")
            : product.unitPrice;
        let totalStock = 0;
        if (product.variants && product.variants.length > 0) {
          product.variants.map((e) => {
            if (e.stock === "" || e.stock === null || e.stock === undefined) {
              e.stock = 0;
            } else {
              console.log(e.stock);
              e.stock = Number(e.stock);
            }
            totalStock = totalStock + Number(e.stock);
            console.log(totalStock);
          });
        } else {
          totalStock = product.stock;
        }
        const newProduct = {
          ...product,
          mainImage: mainImage ? mainImage : product.mainImage,
          secondImage: secondImage ? secondImage : product.secondImage,
          thirdImage: thirdImage ? thirdImage : product.thirdImage,
          fourthImage: fourthImage ? fourthImage : product.fourthImage,
          stock: totalStock,
          unitPrice: parseFloat(unitPrice),
          nameArr: product.name.toLowerCase().split(" "),
          preparation: parseInt(product.preparation),
        };
        const doc = await db.doc(`products/${productId}`).update(newProduct);
        dispatch({
          type: UPDATE_PRODUCT,
          payload: { ...newProduct, productId },
        });
        dispatch(
          addToastrAction({
            type: "success",
            title: "Produit modifié",
            icon: "check",
            message: "Produit modifié avec succès",
          })
        );
      } catch (error) {
        console.error(error);
        dispatch(
          addToastrAction({
            type: "error",
            title: "Erreur",
            message:
              "Erreur lors de la modification du produit. Veuillez réessayer.",
            icon: "exclamation-triangle",
          })
        );
      }
    } else {
      console.log("setProduct action started");
      try {
        const userId = firebase.auth().currentUser.uid;
        const mainImageId = Math.floor(Math.random() * 1000000000000);
        let secondImageId;
        let thirdImageId;
        let fourthImageId;
        await firebase
          .storage()
          .ref(`products/${userId}/${mainImageId}`)
          .put(product.mainImage);
        const mainImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${mainImageId}?alt=media`;
        let secondImage = null;
        let thirdImage = null;
        let fourthImage = null;
        if (product.secondImage) {
          secondImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${secondImageId}`)
            .put(product.secondImage);
          secondImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${secondImageId}?alt=media`;
        }
        if (product.thirdImage) {
          thirdImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${thirdImageId}`)
            .put(product.thirdImage);
          thirdImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${thirdImageId}?alt=media`;
        }
        if (product.fourthImage) {
          fourthImageId = Math.floor(Math.random() * 1000000000000);
          await firebase
            .storage()
            .ref(`products/${userId}/${fourthImageId}`)
            .put(product.fourthImage);
          fourthImage = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/products%2F${userId}%2F${fourthImageId}?alt=media`;
        }
        const unitPrice =
          typeof product.unitPrice === "string"
            ? product.unitPrice.replace(",", ".")
            : product.unitPrice;
        const newProduct = {
          ...product,
          sold: 0,
          sellerId: userId,
          createdAt: new Date(),
          unitPrice: parseFloat(unitPrice),
          stock: parseInt(product.stock),
          mainImage,
          secondImage,
          thirdImage,
          fourthImage,
          sellerName: getState().user.name,
          nameArr: product.name.toLowerCase().split(" "),
          preparation: parseInt(product.preparation),
        };
        const doc = await db.collection("products").add(newProduct);
        console.log("New product added with id :", doc.id);
        dispatch({
          type: SET_PRODUCT,
          payload: { ...newProduct, productId: doc.id },
        });
        dispatch(
          addToastrAction({
            type: "success",
            title: "Produit ajouté",
            icon: "check",
            message: "Produit ajouté avec succès",
          })
        );
      } catch (error) {
        console.error(error);
        dispatch(
          addToastrAction({
            type: "error",
            title: "Erreur",
            message: "Erreur lors de l'ajout du produit. Veuillez réessayer.",
            icon: "exclamation-triangle",
          })
        );
      }
    }
  };

export const getPromotionsAction = () => {
  return async (dispatch, getState) => {
    const todaysDate = new Date();
    try {
      let promotions = [];
      const uid = firebase.auth().currentUser.uid;
      const promotionsSnapshot = await db
        .collection("promotions")
        .where("sellerId", "==", uid)
        .where("endDate", ">", todaysDate)
        .get();
      promotionsSnapshot.forEach((doc) =>
        promotions.push({ ...doc.data(), promotionId: doc.id })
      );
      dispatch({ type: GET_PROMOTIONS, payload: promotions });
    } catch (error) {
      console.error(error);
    }
  };
};

export const setPromotionsAction = (promotion) => {
  return async (dispatch, getState) => {
    try {
      if (!promotion.products || promotion.products.length <= 0) {
        throw new Error("Aucun produit n'a été sélectionné");
      }
      const startDate = new Date(promotion.startDate);
      console.log(startDate);
      const endDate = new Date(promotion.endDate);
      if (endDate < startDate) {
        throw new Error("Date de fin antérieure à la date de début");
      }
      const products = getState().pro.products;
      products.map((e) => {
        if (promotion.products.includes(e.productId)) {
          if (e.promotion && dateUtil(e.promotion.endDate) >= new Date()) {
            console.log("déjà en promo");
            throw new Error(
              "Au moins un produit sélectionné est déjà en promotion"
            );
          }
        }
      });
      const uid = firebase.auth().currentUser.uid;
      const newPromotion = {
        ...promotion,
        sellerId: uid,
        startDate: new Date(startDate.setUTCHours(-1, 0, 0, 0)),
        endDate: new Date(endDate.setUTCHours(22, 59, 0, 0)),
      };
      const doc = await db.collection("promotions").add(newPromotion);
      const promotionId = doc.id;
      promotion.products.forEach(async (e) => {
        await db
          .collection("products")
          .doc(e)
          .update({
            promotion: {
              promotionId,
              name: promotion.name,
              startDate: newPromotion.startDate,
              endDate: newPromotion.endDate,
              percentage: promotion.percentage,
            },
          });
      });
      dispatch({
        type: SET_PROMOTION,
        payload: {
          ...newPromotion,
          promotionId: doc.id,
          startDate: toTimestamp(newPromotion.startDate),
          endDate: toTimestamp(newPromotion.endDate),
        },
      });
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Promotion créée",
          message: "Votre promotion a été créée avec succès",
        })
      );
    } catch (error) {
      console.log(error);
      if (error.message === "Date de fin antérieure à la date de début") {
        dispatch(
          addToastrAction({
            type: "error",
            icon: "exclamation-triangle",
            title: "Erreur",
            message:
              "La date de fin ne peut être antérieure à la date de début",
          })
        );
      } else if (error.message === "Aucun produit n'a été sélectionné") {
        dispatch(
          addToastrAction({
            type: "error",
            icon: "exclamation-triangle",
            title: "Erreur",
            message: "Veuillez sélectionner au moins un produit",
          })
        );
      } else if (
        error.message ===
        "Au moins un produit sélectionné est déjà en promotion"
      ) {
        dispatch(
          addToastrAction({
            type: "error",
            icon: "exclamation-triangle",
            title: "Erreur",
            message: "Au moins un produit sélectionné est déjà en promotion",
          })
        );
      } else {
        dispatch(
          addToastrAction({
            type: "error",
            icon: "exclamation-triangle",
            title: "Erreur",
            message: "Votre promotion n'a pas été créée, veuillez réessayer.",
          })
        );
      }
    }
  };
};

export const removePromotionAction =
  (promotionId) => async (dispatch, getState) => {
    try {
      const promotionSelected = getState().pro.promotions.filter(
        (e) => e.promotionId === promotionId
      )[0];
      promotionSelected.products.forEach(async (f) => {
        await db.collection("products").doc(f).update({
          promotion: firebase.firestore.FieldValue.delete(),
        });
      });
      await db.collection("promotions").doc(promotionId).delete();

      dispatch({ type: REMOVE_PROMOTION, payload: promotionId });
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Promotion supprimée",
          message: "Votre promotion a été supprimée avec succès",
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        addToastrAction({
          type: "error",
          icon: "exclamation-triangle",
          title: "Erreur",
          message: "Votre promotion n'a pas pu être supprimée",
        })
      );
    }
  };

export const removeproductAction =
  (productId) => async (dispatch, getState) => {
    try {
      console.log("removeProduct", productId);

      if (productId) {
        const product = getState().pro.products.filter(
          (e) => e.productId === productId
        )[0];
        const imagesPaths = [
          product.mainImage,
          product.secondImage,
          product.thirdImage,
          product.fourthImage,
        ];
        await db.collection("products").doc(productId).delete();
        imagesPaths.forEach((e) => {
          if (e && e !== "") {
            firebase.storage().refFromURL(e).delete();
          }
        });
        dispatch({ type: REMOVE_PRODUCT, payload: productId });
        dispatch(closeModalAction());
        dispatch(
          addToastrAction({
            type: "success",
            title: "Produit supprimé",
            message: "Votre produit a été supprimé avec succès",
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addToastrAction({
          type: "error",
          icon: "exclamation-triangle",
          title: "Erreur",
          message: "Votre produit n'a pas pu être supprimé. Veuillez réessayer",
        })
      );
    }
  };
