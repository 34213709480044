import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { resetPwdAction } from "../../redux/auth/authActions";
import { closeModalAction } from "../../redux/modal/modalActions";

const Container = styled.form`
  width: 100%;
  label {
    display: block;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }
  input  {
    display: block;
    width: 100%;
    margin: auto;
    padding: 10px;
    border: 1px solid #ededed;
    border-radius: 5px;
  }
  button {
    cursor: pointer;
    font-size: 16px;
    display: block;
    width: 100%;
    margin: auto;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #ffffff;
    background-color: ${(props) => props.theme.franceBlue};
  }
`;

function ResetPasswordModal({ email, closeModalAction, resetPwdAction }) {
  const [resetEmail, setEmail] = useState("");
  useEffect(() => {
    if (email) {
      setEmail(email);
    }
  }, []);
  return (
    <Container>
      <label htmlFor="resetEmail">Adresse E-mail :</label>
      <input
        id="resetEmail"
        type="text"
        onChange={(e) => setEmail(e.target.value)}
        value={resetEmail}
      />
      <br />
      <button
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          resetPwdAction(resetEmail);
          closeModalAction();
        }}
      >
        Réinitialiser
      </button>
    </Container>
  );
}

const mapState = (state, props) => ({});

const mapActions = {
  closeModalAction,
  resetPwdAction,
};

export default connect(mapState, mapActions)(ResetPasswordModal);
