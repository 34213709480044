import { createReducer } from "./../../utils/reducerUtil";
import {
  SET_AUTH,
  RESET_AUTH,
  AUTH_LOADING_START,
  AUTH_LOADING_END,
  AUTH_LOADING_ERROR,
} from "./authConstants";

const initialState = {
  loading: false,
  isLoggedIn: false,
  uid: "",
};

export const setAuth = (state, payload) => {
  return { ...state, isLoggedIn: true, uid: payload.uid };
};

export const resetAuth = (state, payload) => {
  return { ...state, ...initialState };
};

export const authLoadingStart = (state, payload) => {
  return { ...state, loading: true };
};

export const authLoadingEnd = (state, payload) => {
  return { ...state, loading: false };
};

export const authLoadingError = (state, payload) => {
  return { ...state, loading: false };
};

export default createReducer(initialState, {
  [SET_AUTH]: setAuth,
  [RESET_AUTH]: resetAuth,
  [AUTH_LOADING_START]: authLoadingStart,
  [AUTH_LOADING_END]: authLoadingEnd,
  [AUTH_LOADING_ERROR]: authLoadingError,
});
