import { combineReducers } from "redux";
import toastrReducer from "./toastr/toastrReducer";
import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducer";
import proReducer from "./pro/proReducer";
import messengerReducer from "./messenger/messengerReducer";
import modalReducer from "./modal/modalReducer";
import proOrdersReducer from "./proOrders/proOrdersReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  toastr: toastrReducer,
  pro: proReducer,
  messenger: messengerReducer,
  modal: modalReducer,
  proOrders: proOrdersReducer,
});

export default rootReducer;
