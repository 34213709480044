import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { dateUtil } from "../../utils/dateUtil";
import { statusOrdersUtil, toOrderNumber } from "../../utils/ordersUtil";
import theme from "../styles/theme";

const OrderItemContainer = styled.div`
  color: ${(props) => props.theme.grey};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  margin: 20px 40px 20px 40px;
  cursor: pointer;
  font-size: 18px;
  &:hover {
    filter: brightness(0.95);
  }
`;

const OrderNumber = styled.span`
  font-weight: 600;
  font-size: 20px;
  justify-self: stretch;
  text-align: center;
  flex: 7;
`;

const OrderDate = styled.span`
  flex: 1;
`;

const OrderStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-style: italic;
  flex: 2;
`;

const OrderDot = styled.div`
  background-color: ${(props) => props.color};
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  margin-right: 20px;
`;

export const OrderItem = ({ order }) => {
  const selectedStatus = statusOrdersUtil.filter(
    (f) => f.status === order.status
  )[0];
  return (
    <Link
      style={{ color: theme.grey, textDecoration: "none" }}
      to={`/commande/${order.subOrderId}`}
    >
      <OrderItemContainer>
        <OrderDate>
          {dateUtil(order.createdAt).toLocaleDateString("fr-FR")}
        </OrderDate>
        <OrderNumber>{toOrderNumber(order.orderNumber)}</OrderNumber>
        <OrderStatus>
          <OrderDot color={selectedStatus.labelColor} />
          {selectedStatus.short ? selectedStatus.short : "En cours"}
        </OrderStatus>
      </OrderItemContainer>
    </Link>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
