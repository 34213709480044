import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import OrdersProSelector from "../components/OrdersProSelector";
import { OrderItem } from "../components/OrderItem";
import { Link } from "react-router-dom";
import {
  getProOrdersAction,
  setOrdersFilterAction,
} from "../../redux/proOrders/proOrdersAction";

const OrdersPageContainer = styled.div`
  position: relative;
  padding-top: 20px;
  overflow: scroll;
  max-height: calc(100vh - 70px);
  padding-bottom: 20px;
`;

const OrdersItemsContainer = styled.div`
  height: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const NoOrderDiv = styled.div`
  width: 80%;
  color: ${(props) => props.theme.marineBlue};
  background-color: #eaeaea;
  text-align: center;
  font-size: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const RefreshButton = styled.button`
  position: absolute;
  top: 80px;
  right: 80px;
  color: ${(props) => props.theme.franceBlue};
  outline: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 30px;
  &:hover {
    color: ${(props) => props.theme.marineBlue};
  }
`;

const OrdersPro = ({ orders, getProOrdersAction, setOrdersFilterAction }) => {
  useEffect(() => {
    return () => {
      setOrdersFilterAction("all");
    };
  }, []);
  return (
    <OrdersPageContainer>
      <OrdersProSelector />
      <OrdersItemsContainer>
        {orders && orders.length > 0 ? (
          <React.Fragment>
            {orders.map((e) => (
              <OrderItem key={e.subOrderId} order={e} />
            ))}
          </React.Fragment>
        ) : (
          <NoOrderDiv>
            Veuillez traiter les commandes sur l'application mobile. La page
            commande sur le site sera disponible à partir du 20 Avril.
          </NoOrderDiv>
        )}
      </OrdersItemsContainer>
      <RefreshButton
        onClick={(e) => {
          e.preventDefault();
          setOrdersFilterAction("all");
          getProOrdersAction();
        }}
      >
        <i className="fal fa-sync" />
      </RefreshButton>
    </OrdersPageContainer>
  );
};

// OrdersPro.propTypes = {
//   props: PropTypes,
// };

const mapStateToProps = (state) => ({
  orders: state.proOrders.displayedOrders,
});

const mapDispatchToProps = {
  getProOrdersAction,
  setOrdersFilterAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPro);
