import { createReducer } from "./../../utils/reducerUtil";
import {
  CLEAR_USER,
  EDIT_PROFILE,
  GET_USER,
  USER_CREATED,
  USER_LOADING_START,
  USER_LOADING_END,
} from "./userConstants";

const initialState = {};

export const getUser = (state, payload) => {
  return { ...state, ...payload };
};

export const updateUser = (state, payload) => {
  console.log(payload);
  return { ...state, ...payload };
};

export const clearUser = (state, payload) => {
  return {};
};

export const authLoadingStart = (state, payload) => {
  return { ...state, loading: true };
};
export const authLoadingEnd = (state, payload) => {
  return { ...state, loading: false };
};

export const userCreated = (state, payload) => {
  return { ...state, userCreated: true };
};

export default createReducer(initialState, {
  [GET_USER]: getUser,
  [EDIT_PROFILE]: updateUser,
  [CLEAR_USER]: clearUser,
  [USER_LOADING_START]: authLoadingStart,
  [USER_LOADING_END]: authLoadingEnd,
  [USER_CREATED]: userCreated,
});
