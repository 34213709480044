import {
  CLEAR_USER,
  EDIT_PROFILE,
  GET_USER,
  USER_LOADING_END,
  USER_LOADING_START,
  USER_CREATED,
} from "./userConstants";
import { addToastrAction } from "../toastr/toastrActions";
import { toTimestamp } from "../../utils/dateUtil";
import { firebase, db } from "./../../utils/fbUtil";
import { openModalAction, closeModalAction } from "../modal/modalActions";
import { resetAuthAction } from "../auth/authActions";
import axios from "axios";

export const signUpUserAction = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOADING_START });
    // Check data --> Throw err and toastr error if not good
    if (user.password === user.confirmPassword) {
      // if (user.postalCode === ) Check postalCode and exclude DOM TOMs
      const res = await axios.post(
        "https://europe-west1-la-chariotte.cloudfunctions.net/api/new_buyer",
        { ...user, birthDate: new Date("01/01/1979") }
      );
      if (res.status === 200) {
        dispatch(
          addToastrAction({
            type: "success",
            icon: "check",
            title: "Compté créé",
            message: "Vous avez bien créé un compte sur La Chariotte",
          })
        );

        dispatch({ type: USER_LOADING_END });
        dispatch({ type: USER_CREATED });
      } else throw new Error();
    } else {
      throw new Error("Mots de passe non correspondant");
    }
  } catch (error) {
    dispatch({ type: USER_LOADING_END });
    dispatch(
      addToastrAction({
        type: "error",
        icon: "exclamation-triangle",
        title: "Erreur",
        message: "Une erreur s'est produite, veuillez réessayer.",
      })
    );
  }
};

export const getUserAction = (userId) => async (dispatch, getState) => {
  try {
    // console.log("getUserAction started");
    let uid = userId;
    if (firebase.auth().currentUser.uid) {
      uid = firebase.auth().currentUser.uid;
    }
    const doc = await db.doc(`users/${uid}`).get();
    // if (doc.data().termsAccepted === false) {
    //   dispatch(openModalAction({ modalType: "terms" }));
    // }
    const user = { ...doc.data() };
    if (user.userType === "pro") {
      if (user.termsAccepted === false) {
        dispatch(
          openModalAction({
            modalTitle: "Termes et conditions",
            modalType: "terms",
          })
        );
      }
      dispatch({ type: GET_USER, payload: { ...user, uid } });
    } else {
      dispatch(resetAuthAction());
      dispatch(
        addToastrAction({
          type: "error",
          title: "Erreur",
          message:
            "Le site est accessible seulement aux vendeurs, veuillez télécharger l'application",
        })
      );
      throw new Error("Erreur, profil acheteur");
    }
  } catch (error) {
    // console.error(error);
    dispatch(
      addToastrAction({
        type: "error",
        title: "Erreur",
        message:
          "Erreur lors de la récupération de votre profil. Veuillez réessayer.",
      })
    );
  }
};

export const updateUserAction = (user) => async (dispatch, getState) => {
  // console.log("updateUserAction started");
  let emailError = false;
  if (user.email !== firebase.auth().currentUser.email) {
    try {
      await firebase.auth().currentUser.updateEmail(user.email);
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Adresse e-mail modifiée",
          message: "Votre adresse e-mail a été modifié avec succès.",
        })
      );
    } catch (error) {
      emailError = true;
      dispatch(
        addToastrAction({
          type: "error",
          icon: "exclamation-triangle",
          title: "Echec",
          message:
            "Erreur : Veuillez vous déconnecter puis vous reconnecter pour pouvoir mettre à jour votre adresse email",
        })
      );
    }
  }
  if (!emailError) {
    try {
      let imageUrl = user.imageUrl;
      const uid = firebase.auth().currentUser.uid;
      if (typeof imageUrl !== "string" || !imageUrl.startsWith("https://")) {
        const imageUrlId = Math.floor(Math.random() * 1000000000000);
        await firebase
          .storage()
          .ref(`users/${uid}/${imageUrlId}`)
          .put(user.imageUrl);
        imageUrl = `https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/users%2F${uid}%2F${imageUrlId}?alt=media`;
      }
      let updatedUser;
      if (user.userType === "pro") {
        console.log(user);
        const minFreeShip =
          typeof user.minFreeShipAmount === "string"
            ? user.minFreeShipAmount.replace(",", ".")
            : user.minFreeShipAmount;
        const minShip =
          typeof user.minShipAmount === "string"
            ? user.minShipAmount.replace(",", ".")
            : user.minShipAmount;
        const shipPrice =
          typeof user.shippingOptions[0].price === "string"
            ? user.shippingOptions[0].price.replace(",", ".")
            : user.shippingOptions[0].price;
        updatedUser = {
          ...user,
          minShipAmount: parseFloat(minShip),
          minFreeShipAmount: parseFloat(minFreeShip),
          imageUrl: imageUrl,
          shippingOptions: [
            {
              name: user.shippingOptions[0].name,
              price: parseFloat(shipPrice),
            },
          ],
        };
      } else {
        updatedUser = {
          ...user,
          imageUrl,
        };
      }

      await db.collection("users").doc(uid).update(updatedUser);
      if (user.userType === "pro") {
        dispatch({
          type: EDIT_PROFILE,
          payload: { ...user, imageUrl: updatedUser.imageUrl, uid },
        });
      } else {
        dispatch({
          type: EDIT_PROFILE,
          payload: { ...user, birthDate: toTimestamp(user.birthDate), uid },
        });
      }

      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Profil modifié",
          message: "Votre profil a été modifié avec succès",
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        addToastrAction({
          type: "error",
          icon: "exclamation-triangle",
          title: "Echec",
          message:
            "Erreur : Votre profil n'a pas pu être modifié. Veuillez réessayer",
        })
      );
    }
  }
};

export const clearUserAction = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_USER });
};

// export const addFCMTokenAction = (token) => async (dispatch, getState) => {
//   try {
//     const userFCMToken = getState().user.FCMToken;
//     if (userFCMToken !== token && auth().currentUser) {
//       await db
//         .collection("users")
//         .doc(auth().currentUser.uid)
//         .update({ FCMToken: token });
//       dispatch({ type: ADD_FCM_TOKEN, payload: token });
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

export const validateTermsAction = () => async (dispatch, getState) => {
  try {
    await db.collection("users").doc(firebase.auth().currentUser.uid).update({
      termsAccepted: true,
      termsAcceptedOn: new Date(),
    });
    dispatch(closeModalAction());
    dispatch(
      addToastrAction({
        type: "success",
        icon: "check",
        title: "Bienvenue",
        message: "Vous pouvez maintenant utiliser l'application",
      })
    );
  } catch (error) {
    // console.log(error);
    dispatch(
      addToastrAction({
        type: "error",
        icon: "exclamation-triangle",
        title: "Erreur",
        message: "Veuillez rédémarrer l'application et essayer à nouveau.",
      })
    );
  }
};
