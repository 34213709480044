import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { openModalAction } from "../../redux/modal/modalActions";
import { dateUtil, remainingTime } from "../../utils/dateUtil";

const PromoContainer = styled.div`
  width: 80%;
  margin-bottom: 30px;
  background-color: ${(props) => props.theme.orange};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.grey};
  padding: 20px 40px 20px 40px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
`;

const BolderText = styled.span`
  font-weight: 600;
`;

const PromotionTitle = styled.div`
  font-size: 22px;
`;

const PromotionItem = ({ promotion, openModalAction }) => {
  const todaysDate = new Date();
  if (promotion && dateUtil(promotion.endDate) > todaysDate) {
    return (
      <PromoContainer
        onClick={() =>
          openModalAction({ modalType: "promotion", modalContent: promotion })
        }
      >
        <div>
          <PromotionTitle>{promotion.name}</PromotionTitle>
          <BolderText>- {promotion.percentage}%</BolderText>
        </div>
        <BolderText>
          Temps restant : {remainingTime(dateUtil(promotion.endDate))}
        </BolderText>
      </PromoContainer>
    );
  } else return null;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionItem);
