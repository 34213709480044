import { Formik, Form } from "formik";
import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModalAction } from "../../redux/modal/modalActions";
import { setProductAction } from "../../redux/pro/proActions";
import ImageInput from "../components/Form/ImageInput";
import Select from "../components/Form/Select";
import TextArea from "../components/Form/TextArea";
import TextInput from "../components/Form/TextInput";
import theme from "../styles/theme";
import * as Yup from "yup";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO : Calculate totalStock in reducer

const VariantsButton = styled.button`
  display: block;
  border: 1px solid ${(props) => props.theme.franceBlue};
  border-radius: 5px;
  padding: 7px;
  background: #fff;
  font-size: 16px;
  color: ${(props) => props.theme.franceBlue};
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.franceBlue};
    color: #fff;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.green};
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

const Icon = styled.i`
  font-size: ${(props) => (props.size ? props.size : "15px")};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};
`;

const Row = styled.div`
  display: flex;
`;

const ErrorSpan = styled.span`
  font-size: 12px;
  display: block;
  color: ${(props) => props.theme.lightRed};
  font-style: italic;
`;

const TextLabel = styled.label`
  width: 50%;
`;

const ProductForm = ({ product, setProductAction, closeModalAction }) => {
  const initialValues = {
    category: "",
    mainImage: "",
    secondImage: "",
    thirdImage: "",
    fourthImage: "",
    name: "",
    description: "",
    quantity: "",
  };
  const variantsFn = () => {
    if (product.variants && product.variants.length > 0) {
      if (product.variants[0].name) {
        return product.variants.map((e) => ({
          name: e.name,
          stock: e.stock.toString(),
        }));
      } else {
        return product.variants.map((e) => ({ name: e, stock: "" }));
      }
    } else {
      return null;
    }
  };
  return (
    <Formik
      initialValues={
        product
          ? {
              ...product,
              variants: variantsFn(),
            }
          : initialValues
      }
      onSubmit={(values) => {
        if (product) {
          setProductAction(values, product.productId);
        } else {
          setProductAction(values);
        }
        closeModalAction();
      }}
      validationSchema={Yup.object().shape({
        mainImage: Yup.string().required("Au moins une image est requise"),
        name: Yup.string().required("Nom du produit requis"),
        unitPrice: Yup.string()
          .required("Prix requis")
          .test("format invalide", "Format invalide", (value) =>
            /^(^(.*[^0-9]|)(1000|[1-9]\d{0,2})([^0-9].*|)$)?$/.test(value)
          ),

        stock: Yup.string().required("Stock requis"),
        quantity: Yup.string()
          .required("Quantité requise")
          .max(10, "Trop long"),
        category: Yup.string().required("Catégorie requise"),
        description: Yup.string().required("Description requise"),
        preparation: Yup.string().required(
          "Merci d'indiquer un délai de préparation"
        ),
      })}
    >
      {({ setFieldValue, values, errors, handleChange }) => (
        <Form>
          <Row>
            <Column>
              <ImageInput
                setFieldValue={setFieldValue}
                name="mainImage"
                imageSize="230px"
                value={values.mainImage}
              />
              <Row>
                <ImageInput
                  setFieldValue={setFieldValue}
                  name="secondImage"
                  imageSize="70px"
                  value={values.secondImage}
                />
                <ImageInput
                  setFieldValue={setFieldValue}
                  name="thirdImage"
                  imageSize="70px"
                  value={values.thirdImage}
                />
                <ImageInput
                  setFieldValue={setFieldValue}
                  name="fourthImage"
                  imageSize="70px"
                  value={values.fourthImage}
                />
              </Row>
              {errors.mainImage && <ErrorSpan>{errors.mainImage}</ErrorSpan>}
            </Column>

            <Column style={{ marginLeft: 10 }}>
              <TextInput
                label="Nom du produit *"
                placeholder="Ex : Tomates"
                value={values.name}
                name="name"
                onChange={handleChange}
                error={errors.name}
              />
              <TextInput
                label="Quantité *"
                placeholder='Ex : 500g (ou "unité")'
                value={values.quantity}
                name="quantity"
                onChange={handleChange}
                error={errors.quantity}
              />
              <Row>
                <TextInput
                  label="Prix (€) *"
                  placeholder="Ex : 29"
                  value={values.unitPrice}
                  name="unitPrice"
                  type="number"
                  onChange={handleChange}
                  error={errors.unitPrice}
                />
                {(!values.variants || values.variants.length === 0) && (
                  <TextInput
                    label="Stock *"
                    placeholder="Ex : 100"
                    value={values.stock}
                    name="stock"
                    type="number"
                    onChange={handleChange}
                    error={errors.stock}
                  />
                )}
              </Row>
              <Select
                setFieldValue={setFieldValue}
                name="category"
                value={values.category}
                label="Catégorie *"
                error={errors.category}
              />
            </Column>
          </Row>

          <TextArea
            label="Description du produit *"
            placeholder="Description..."
            value={values.description}
            name="description"
            onChange={handleChange}
            error={errors.description}
          />
          <TextInput
            label="Délai de préparation (jours) *"
            placeholder="Ex : 2"
            name="preparation"
            value={values.preparation}
            onChange={handleChange}
            error={errors.preparation}
          />
          <VariantsButton
            onClick={(e) => {
              e.preventDefault();

              if (values.variants) {
                setFieldValue("variants", [
                  ...values.variants,
                  { name: "", stock: "" },
                ]);
              } else {
                setFieldValue("variants", [{ name: "", stock: "" }]);
              }
            }}
          >
            <Icon
              className="fal fa-layer-group"
              color={theme.franceBlue}
              mr="5px"
            />
            Ajouter des variantes
          </VariantsButton>
          {values.variants &&
            values.variants.length > 0 &&
            values.variants.map((e, i) => (
              <div>
                {i === 0 && (
                  <Row
                    style={{
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <TextLabel>Nom de la variante</TextLabel>
                    <TextLabel>Stock</TextLabel>
                  </Row>
                )}

                <Row key={i} style={{ alignItems: "center" }}>
                  <TextInput
                    placeholder="Ex : Taille S"
                    value={values.variants[i].name}
                    onChange={handleChange(`variants[${i}].name`)}
                  />
                  <TextInput
                    placeholder="Stock"
                    value={values.variants[i].stock}
                    onChange={handleChange(`variants[${i}].stock`)}
                  />
                  <Icon
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue(values.variants.splice(i, 1));
                    }}
                    className="fal fa-trash-alt"
                    style={{
                      color: theme.lightRed,
                      marginBottom: 10,
                      cursor: "pointer",
                    }}
                    size="20px"
                  />
                </Row>
              </div>
            ))}
          <SubmitButton type="submit">Enregistrer</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

// ProductForm.propTypes = {
//     props: PropTypes
// }

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setProductAction,
  closeModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
