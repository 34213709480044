import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { logInAction } from "./../../redux/auth/authActions";
import Loader from "../components/Loader";
import { openModalAction } from "../../redux/modal/modalActions";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import * as Yup from "yup";
import { Form, Formik } from "formik";

const FullContainer = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Warning = styled.div`
  color: ${(props) => props.theme.lightRed};
  text-align: center;
  padding: 40px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const TextInputContainer = styled.div`
  padding: 7px;
  border-radius: 4px;
  display: block;
  margin-bottom: 5px;
  width: 300px;
  margin-top: 15px;
  z-index: 2;
  box-shadow: ${(props) =>
    props.active
      ? "0px 2px 10px rgba(22, 93, 191, 0.5)"
      : "2px 2px 10px rgba(0, 0, 0, 0.2)"};

  color: box-shadow: ${(props) =>
    props.active ? props.theme.franceBlue : props.theme.mediumGrey};
`;

const TextInput = styled.input`
  width: 250px;
  border: none;
  margin-left: 10px;
  height: 25px;
  outline: 0;
  font-size: 15px;
`;

const LoginButton = styled.button`
  width: 300px;
  height: 40px;
  color: #ffffff;
  background-color: ${(props) => props.theme.franceBlue};
  outline: 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.marineBlue};
  }
`;

const ResetPwdButton = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.franceBlue};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.marineBlue};
  }
`;

const ErrorLabel = styled.span`
  display: block;
  color: ${(props) => props.theme.red};
  font-size: 12px;
  margin-left: 5px;
  font-style: italic;
`;

const Login = ({
  logInAction,
  history,
  authLoading,
  isAuthenticated,
  openModalAction,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, []);
  const [fieldSelected, setFieldSelected] = useState(null);
  return (
    <FullContainer>
      <Warning>
        Le site est accessible uniquement pour les professionnels pour
        l'instant, il sera accessible à tous dans quelques jours. <br />
        Toutefois, vous pouvez vous créer un compte sur le site pour avoir accès
        à notre application mobile.
      </Warning>
      <Container>
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            const user = { email: values.email, password: values.password };
            logInAction(user, history);
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, "Mot de passe trop court")
              .required("Champ requis"),
            email: Yup.string()
              .email("Email invalide")
              .required("Champ requis"),
          })}
        >
          {(props) => (
            <Form>
              <TextInputContainer
                active={fieldSelected && fieldSelected === "email"}
                onFocus={() => setFieldSelected("email")}
                onBlur={() => setFieldSelected(null)}
              >
                <i className="fal fa-at" />
                <TextInput
                  placeholder="Adresse e-mail"
                  name="email"
                  onChange={props.handleChange("email")}
                />
              </TextInputContainer>
              {props.touched.email && props.errors.email && (
                <ErrorLabel>{props.errors.email}</ErrorLabel>
              )}

              <TextInputContainer
                active={fieldSelected && fieldSelected === "password"}
                onFocus={() => setFieldSelected("password")}
                onBlur={() => setFieldSelected(null)}
              >
                <i className="fal fa-lock" />
                <TextInput
                  placeholder="Mot de passe"
                  type="password"
                  name="password"
                  onChange={props.handleChange("password")}
                />
              </TextInputContainer>
              {props.touched.password && props.errors.password && (
                <ErrorLabel>{props.errors.password}</ErrorLabel>
              )}
              <LoginButton type="submit">
                {authLoading ? <Loader /> : "SE CONNECTER"}
              </LoginButton>
              <ResetPwdButton
                onClick={(e) => {
                  e.preventDefault();
                  openModalAction({
                    modalTitle: "Réinitialiser le mot de passe",
                    modalContent: props.values.email,
                    modalType: "reset-password",
                  });
                }}
              >
                Mot de passe oublié ?
              </ResetPwdButton>
            </Form>
          )}
        </Formik>
      </Container>
    </FullContainer>
  );
};

Login.propTypes = {};

const mapState = (state, props) => ({
  authLoading: state.auth.loading,
  isAuthenticated: state.auth.isLoggedIn,
});

const mapActions = {
  logInAction,
  openModalAction,
};

export default connect(mapState, mapActions)(Login);
