import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModalAction } from "../../../redux/modal/modalActions";
import { setProOrderStatusAction } from "../../../redux/proOrders/proOrdersAction";
import theme from "../../styles/theme";

const Container = styled.div``;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

const Text = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.grey)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

const Button = styled.button`
  background-color: #ffffff;
  color: ${(props) => props.color};
  font-size: 18px;
  width: 150px;
  padding: 15px;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border: none;
    background-color: ${(props) => props.color};
    color: #ffffff;
  }
`;

const TextInput = styled.input`
  display: block;
  margin: 20px auto 20px auto;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.lightRed : props.theme.lighterGrey)};
`;

const ShipModal = ({
  subOrderId,
  closeModalAction,
  setProOrderStatusAction,
}) => {
  const [shipLink, setShipLink] = useState("");
  const [textError, setTextError] = useState(true);

  return (
    <Container>
      <Title>EXP&Eacute;DIER LA COMMANDE</Title>
      <Text>
        Veuillez renseigner le lien de suivi ou le numéro de suivi de la
        commande ainsi que le nom du transporteur.
      </Text>

      <TextInput
        error={textError}
        onChange={(e) => {
          if (e.target.value === "") {
            setTextError(true);
            setShipLink(e.target.value);
          } else {
            setTextError(false);
            setShipLink(e.target.value);
          }
        }}
        value={shipLink}
        placeholder="Lien ou informations de suivi"
      />
      <ButtonsContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            closeModalAction();
          }}
          color={theme.lightRed}
        >
          ANNULER
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (shipLink !== "") {
              closeModalAction();
              setProOrderStatusAction(subOrderId, 5, shipLink);
            } else {
              setTextError();
            }
          }}
          color={theme.green}
        >
          VALIDER
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  closeModalAction,
  setProOrderStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipModal);
