import React, { useState } from "react";
import styled from "styled-components";

const ProductContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-width: 3px;
  border-radius: 5px;
  border-style: solid;
  margin-right: 20px;
  cursor: pointer;
  border-color: ${(props) =>
    props.selected ? props.theme.lighterGreen : props.theme.lightGrey};
`;

const Image = styled.img`
  width: 94px;
  height: 94px;
`;

const CheckIcon = styled.i`
  background-color: ${(props) => props.theme.lighterGreen};
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  padding: 5px;
  border-radius: 5px;
  z-index: 2;
`;

const Tooltip = styled.label`
  position: absolute;
  background-color: ${(props) =>
    props.selected ? props.theme.lighterGreen : props.theme.lightGrey};
  font-weight: bold;
  color: #ffffff;
  font-size: 13px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
`;

function ProductPickerItem({ action, product, products }) {
  const [tooltip, setTooltip] = useState(false);
  const productSelected =
    products.filter((e) => e === product.productId).length > 0;
  const newProducts = products.filter((e) => e !== product.productId);
  if (product) {
    return (
      <ProductContainer
        selected={productSelected}
        onMouseOver={() => setTooltip(true)}
        onMouseOut={() => setTooltip(false)}
        onClick={() => {
          if (productSelected) {
            action(`products`, newProducts);
          } else {
            action(`products[${products.length}]`, product.productId);
          }
        }}
      >
        {product.mainImage && <Image src={product.mainImage} />}
        {productSelected && <CheckIcon className="fas fa-check" />}
        {tooltip && (
          <Tooltip selected={productSelected}>{product.name}</Tooltip>
        )}
      </ProductContainer>
    );
  } else return null;
}

export default ProductPickerItem;
