import {
  SET_FILTER,
  GET_PRO_ORDERS,
  SET_PRO_ORDER_STATUS,
} from "./proOrdersConstants";
import { db, firebase } from "./../../utils/fbUtil";
import { addToastrAction } from "../toastr/toastrActions";
import axios from "axios";
import { closeModalAction } from "../modal/modalActions";

export const setOrdersFilterAction = (filterType) => (dispatch) => {
  dispatch({ type: SET_FILTER, payload: filterType });
};

export const setProOrderStatusAction = (subOrderId, status, message) => async (
  dispatch,
  getState
) => {
  try {
    const sellerId = getState().user.uid;
    const subOrder = getState().proOrders.ordersList.filter((e) => {
      console.log(e.subOrderId);
      return e.subOrderId === subOrderId;
    })[0];

    const parentOrderId = subOrder.orderId;

    let updateObj;
    if (status === 5) {
      updateObj = {
        status,
        tracking: message,
      };
    } else if (status === 4) {
      const transfer = subOrder.transfer;
      const res = await axios.post(
        // 'http://localhost:5000/la-chariotte/europe-west1/api/cancel_order/',
        "https://europe-west1-la-chariotte.cloudfunctions.net/api/cancel_order/",
        {
          transferId: transfer.id,
          transferAmount: transfer.amount,
          sellerId,
          paymentIntentId: transfer.paymentIntentId,
          subOrderId,
          subOrder,
          parentOrderId,
          userType: "pro",
        }
      );
      console.log(res.data);
      updateObj = {
        status,
        cancelMessage: message,
      };
    } else if (status === 3) {
      updateObj = {
        status,
      };
    }
    await db
      .collection("orders")
      .doc(parentOrderId)
      .collection("subOrders")
      .doc(subOrderId)
      .update({
        ...updateObj,
      });
    if (status === 4) {
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Commande annulée",
          message: "Vous avez annulé la commande",
        })
      );
    } else if (status === 3) {
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Commande validée",
          message:
            "Vous avez validé la commande, vous êtes tenus de respecter vos délais de préparation",
        })
      );
    } else if (status === 5) {
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Commande expédiée",
          message:
            "Vous avez expédié la commande, vous serez notifié lorsque le client validera la réception",
        })
      );
    }
    dispatch({
      type: SET_PRO_ORDER_STATUS,
      payload: { subOrderId, status, parentOrderId },
    });
  } catch (error) {
    console.error(error);
    dispatch(
      addToastrAction({
        type: "error",
        icon: "exclamation-triangle",
        title: "Commande non mise à jour",
        message:
          "Le statut de la commande n'a pas pu être mis à jour, veuillez réessayer.",
      })
    );
  }
};

// export const getProOrdersAction = (orders) => {
//   return async (dispatch, getState) => {
//     console.log("getProOrdersAction Started");
//     try {
//       let subOrders = [];
//       const subOrderSnapshot = await db
//         .collectionGroup("subOrders")
//         .where("sellerId", "==", uid)
//         .orderBy("createdAt", "desc")
//         .get();
//       subOrderSnapshot.forEach(async (doc) => {
//         subOrders.push({ ...doc.data(), subOrderId: doc.id });
//       });
//       const newOrders = orders.map((e) => {
//         const subOrder = subOrders.filter((f) => f.orderId === e.orderId)[0];
//         if (subOrder && subOrder !== []) {
//           e.subOrder = subOrder;
//         }
//         return e;
//       });
//       dispatch({ type: GET_PRO_ORDERS, payload: newOrders });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

export const getProOrdersAction = () => {
  return async (dispatch, getState) => {
    console.log("Get pro orders action started");
    const uid = firebase.auth().currentUser.uid;
    let subOrders = [];
    let orders = [];
    try {
      const orderSnapshot = await db
        .collection("orders")
        .where("sellers", "array-contains", uid)
        .orderBy("createdAt", "desc")
        .get();
      orderSnapshot.forEach((doc) => {
        const fullOrder = doc.data();
        orders.push({
          buyerId: fullOrder.buyerId,
          sAddress: fullOrder.sAddress,
          sCity: fullOrder.sCity,
          sPostalCode: fullOrder.sPostalCode,
          sFirstName: fullOrder.sFirstName,
          sLastName: fullOrder.sLastName,
          sEmailAddress: fullOrder.sEmailAddress,
          sPhoneNumber: fullOrder.sPhoneNumber,
          orderId: doc.id,
        });
      });
      const subOrderSnapshot = await db
        .collectionGroup("subOrders")
        .where("sellerId", "==", uid)
        .orderBy("createdAt", "desc")
        .get();
      subOrderSnapshot.forEach(async (doc) => {
        subOrders.push({ ...doc.data(), subOrderId: doc.id });
      });
      const newOrders = orders.map((e) => {
        const subOrder = subOrders.filter((f) => f.orderId === e.orderId)[0];
        if (subOrder && subOrder !== []) {
          e.subOrder = subOrder;
        }
        return e;
      });
      dispatch({ type: GET_PRO_ORDERS, payload: newOrders });
    } catch (error) {
      console.log(error);
    }
  };
};

export const refundOrderAction = () => async (dispatch, getState) => {};

export const reportDisputeAction = (subOrderId, orderId) => async (
  dispatch,
  getState
) => {
  try {
    const res = await axios.post(
      // 'http://localhost:5000/la-chariotte/europe-west1/api/report_dispute/',
      "https://europe-west1-la-chariotte.cloudfunctions.net/api/report_dispute/",
      { subOrderId, orderId }
    );
    dispatch(closeModalAction());
    if (res.status === 200) {
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          title: "Litige signalé",
          message: "Litige signalé. Nous vous contacterons prochainement",
        })
      );
    } else {
      throw new Error("Litige non signalé");
    }
  } catch (error) {
    dispatch(
      addToastrAction({
        type: "error",
        icon: "exclamation-triangle",
        title: "Erreur",
        message:
          "Une erreur est survenue, veuillez réessayer ou nous contacter par e-mail.",
      })
    );
  }
};
