import { connect } from "react-redux";
import React from "react";
import styled from "styled-components";
import { dateUtil } from "../../utils/dateUtil";
import ProductItem from "../components/ProductItem";
import { removePromotionAction } from "../../redux/pro/proActions";
import { closeModalAction } from "../../redux/modal/modalActions";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: ${(props) => (props.red ? "18px" : "20px")};
  margin-bottom: 10px;
  color: ${(props) => (props.red ? props.theme.lightRed : props.theme.grey)};
`;

const DateText = styled.div`
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const RemovePromotionButton = styled.button`
  padding: 15px;
  color: #ffffff;
  background-color: ${(props) => props.theme.lightRed};
  outline: 0;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
  i {
    font-size: 20px;
    margin-right: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

function PromotionModal({
  promotion,
  products,
  removePromotionAction,
  closeModalAction,
}) {
  const selectedProducts = promotion.products.map((productId) => {
    return products.filter((e) => productId === e.productId)[0];
  });

  if (promotion) {
    return (
      <Container>
        <Title>Nom : {promotion.name}</Title>
        <Title red>Réduction : - {promotion.percentage}%</Title>
        <DateText>
          Date de début de la promotion :{" "}
          {dateUtil(promotion.startDate).toLocaleDateString("fr-FR")}
        </DateText>
        <DateText>
          Date de fin de la promotion :{" "}
          {dateUtil(promotion.endDate).toLocaleDateString("fr-FR")}
        </DateText>
        <SubTitle>Produit(s) en promotion : </SubTitle>
        <ProductContainer>
          {selectedProducts &&
            selectedProducts.map((e) => (
              <ProductItem key={e.productId} product={e} small />
            ))}
        </ProductContainer>
        <RemovePromotionButton
          onClick={() => {
            closeModalAction();
            removePromotionAction(promotion.promotionId);
          }}
        >
          <i className="fal fa-trash-alt" />
          Supprimer la promotion
        </RemovePromotionButton>
      </Container>
    );
  } else return null;
}

const mapState = (state, props) => ({
  products: state.pro.products,
});

const mapActions = {
  removePromotionAction,
  closeModalAction,
};

export default connect(mapState, mapActions)(PromotionModal);
