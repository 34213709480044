export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const SET_AUTH = "SET_AUTH";
export const RESET_AUTH = "RESET_AUTH";
export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const EDIT_PASSWORD = "EDIT_PASSWORD";
export const LOGOUT = "LOGOUT";
export const AUTH_LOADING_START = "AUTH_LOADING_START";
export const AUTH_LOADING_END = "AUTH_LOADING_END";
export const AUTH_LOADING_ERROR = "AUTH_LOADING_ERROR";
