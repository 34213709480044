import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./config/ScrollToTop";
import { connect } from "react-redux";
import {
  LoginPage,
  OrdersProPage,
  ProductsPage,
  DashboardPage,
  MessengerPage,
  ShopProPage,
  OrderProPage,
  PromotionsPage,
  SalesProPage,
  SignUpPage,
} from "./layout/pages/index";
import AuthRoute from "./utils/AuthRoute";
import Menu from "./layout/components/Menu";
import { firebase, db } from "./utils/fbUtil";
import { checkAuthAction, setUserAction } from "./redux/auth/authActions";
import Loader from "./layout/components/Loader";
import styled from "styled-components";
import { getProductsAction, getPromotionsAction } from "./redux/pro/proActions";
import { getConversationsAction } from "./redux/messenger/messengerActions";
import Modal from "./layout/modals/Modal";
import ToastrContainer from "./layout/components/ToastrContainer";
import { getProOrdersAction } from "./redux/proOrders/proOrdersAction";

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DownloadLogos = styled.img`
  display: block;
  margin: 10px auto 10px auto;
  width: 200px;
`;

const App = ({
  isFetching,
  isAuthenticated,
  setUserAction,
  history,
  getProductsAction,
  getConversationsAction,
  getProOrdersAction,
  getPromotionsAction,
  modalOpen,
  ...props
}) => {
  // TODO : if screen size is <850px ==> Display page to download the app
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await setUserAction(user);
        await getProductsAction();
        await getProOrdersAction();
        await getPromotionsAction();
      } else {
        // reset products
        // resetAuth
        // resetUser
        // resetConversations
        // resetProOrders
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  if (loading) {
    return (
      <React.Fragment>
        {modalOpen ? <Modal /> : null}
        <ToastrContainer />
        <ScrollToTop>
          <Menu />
          <CenteredDiv>
            <Loader dark />
          </CenteredDiv>
        </ScrollToTop>
      </React.Fragment>
    );
  } else {
    if (
      window.innerWidth < 600 &&
      window.location.pathname !== "/connexion" &&
      window.location.pathname !== "/enregistrement"
    ) {
      return (
        <div
          style={{
            flex: 1,
            textAlign: "center",
            padding: 20,
            height: "100%",
            width: "100%",
          }}
        >
          Cette résolution n'est pas supportée par le site. Vous pouvez
          toutefois télécharger notre application en cliquant sur un des bouton
          suivant :
          <a
            id="button"
            href="https://apps.apple.com/fr/app/la-chariotte/id1544658551"
          >
            <DownloadLogos
              src="https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/apple-store-button.png?alt=media"
              target="_blank"
              alt="Télécharger pour iPhone"
            />
          </a>
          <a
            id="button"
            href="https://play.google.com/store/apps/details?id=com.lachariottenative"
          >
            <DownloadLogos
              target="_blank"
              src="https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/google-play-button.png?alt=media"
              alt="Télécharger pour Android"
            />
          </a>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {modalOpen ? <Modal /> : null}
          <ToastrContainer />
          <ScrollToTop>
            <Menu />
            <div style={{ paddingTop: 70 }}>
              <Switch>
                {isAuthenticated ? (
                  <>
                    <Route
                      exact
                      path="/"
                      render={(props) => <Redirect to="/accueil" />}
                    />
                    <Route exact path="/connexion" component={LoginPage} />
                    <Route exact path="/accueil" component={DashboardPage} />
                    <Route exact path="/messagerie" component={MessengerPage} />
                    <Route exact path="/produits" component={ProductsPage} />
                    <Route exact path="/commandes" component={OrdersProPage} />
                    <Route exact path="/rapport" component={SalesProPage} />
                    <Route
                      path="/commande/:subOrderId"
                      component={OrderProPage}
                    />
                    <Route
                      exact
                      path="/promotions"
                      component={PromotionsPage}
                    />
                    <Route exact path="/boutique" component={ShopProPage} />
                    <Route exact path="/commande" component={OrderProPage} />
                    <Route render={(props) => <Redirect to="/accueil" />} />
                  </>
                ) : (
                  <>
                    <Route
                      path="/(.+)"
                      render={(props) => <Redirect to="/connexion" />}
                    />
                    <Route
                      exact
                      path="/"
                      render={(props) => <Redirect to="/connexion" />}
                    />
                    <Route exact path="/connexion" component={LoginPage} />
                    <Route
                      exact
                      path="/enregistrement"
                      component={SignUpPage}
                    />
                    {/* <Route component={LoginPage} /> */}
                    {/* <Route render={(props) => <Redirect to="/accueil" />} /> */}
                  </>
                )}
              </Switch>
            </div>
          </ScrollToTop>
        </React.Fragment>
      );
    }
  }
};

const mapState = (state, props) => ({
  isFetching: state.auth.loading,
  isAuthenticated: state.auth.isLoggedIn,
  modalOpen: state.modal.open,
});

const mapActions = {
  checkAuthAction,
  setUserAction,
  getProductsAction,
  getConversationsAction,
  getPromotionsAction,
  getProOrdersAction,
};

export default connect(mapState, mapActions)(App);
