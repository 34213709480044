import {
  SEND_MESSAGE,
  GET_CONVERSATIONS,
  GET_CONVERSATION,
  NEW_CONVERSATION_STARTED,
  SEND_MESAGE_OUTSIDE,
  RESET_CONVERSATIONS,
} from "./messengerConstants";
import { db, firebase } from "./../../utils/fbUtil";
import { addToastrAction } from "../toastr/toastrActions";

// NEW

export const resetMessengerAction = () => (dispatch, getState) => {
  dispatch({ type: RESET_CONVERSATIONS });
};

export const sendMessageToUserAction = (userId, message) => async (
  dispatch,
  getState
) => {
  const uid = firebase.auth().currentUser.uid;
  try {
    const conversation = getState().messenger.conversations.filter(
      (e) => e.userTwo.userId === userId
    )[0];
    if (conversation && conversation !== []) {
      dispatch(sendMessageAction(conversation.conversationId, message, true));
    } else {
      const newConversation = {
        lastMessage: message,
        lastMessageAt: new Date(),
        createdAt: new Date(),
        users: [userId, firebase.auth().currentUser.uid],
        lastMessageSenderId: firebase.auth().currentUser.uid,
      };
      const newConvSnapshot = await db
        .collection("conversations")
        .add(newConversation);
      const conversationId = newConvSnapshot.id;
      const newMessage = {
        message,
        createdAt: new Date(),
        sentBy: firebase.auth().currentUser.uid,
      };
      await db
        .collection("conversations")
        .doc(conversationId)
        .collection("messages")
        .add(newMessage);
      const newConversations = await addUserTwoToConv([newConversation], uid);
      dispatch(
        addToastrAction({
          type: "success",
          title: "Message envoyé",
          message: "Message envoyé avec succès",
          icon: "check",
        })
      );
    }
  } catch (error) {
    console.log({ error });
    console.error(error);
    dispatch(
      addToastrAction({
        type: "error",
        title: "Erreur",
        message: "Erreur lors de l'envoi du message. Veuillez réessayer.",
        icon: "exclamation-triangle",
      })
    );
  }
};

// NEW

export const sendMessageAction = (
  conversationId,
  message,
  outside = false
) => async (dispatch, getState) => {
  try {
    console.log("sendMessageAction started");
    const uid = firebase.auth().currentUser.uid;
    const newMessage = {
      message,
      createdAt: new Date(),
      sentBy: uid,
    };
    const conversations = getState().messenger.conversations;
    const conversation = conversations.filter((conv) => {
      return conv.conversationId === conversationId;
    })[0];

    const newConversation = {
      ...conversation,
      lastMessage: message,
      lastMessageAt: newMessage.createdAt,
      lastMessageSenderId: uid,
    };
    const { userTwo, ...conversationWithoutUserTwo } = newConversation;
    const doc = await db
      .collection("conversations")
      .doc(conversationId)
      .collection("messages")
      .add(newMessage);
    await db
      .doc(`conversations/${conversationId}`)
      .update(conversationWithoutUserTwo);
    // if (outside) {
    //   dispatch({
    //     type: SEND_MESAGE_OUTSIDE,
    //     payload: {
    //       conversationId,
    //       newConversation,
    //     },
    //   });
    // } else {
    //   dispatch({
    //     type: SEND_MESSAGE,
    //     payload: {
    //       message: { ...newMessage, messageId: doc.id },
    //       conversationId,
    //       newConversation,
    //     },
    //   });
    // }
  } catch (error) {
    console.error("error send message", error);
    dispatch(
      addToastrAction({
        type: "error",
        title: "Erreur",
        message: "Erreur lors de l'envoi du message. Veuillez réessayer.",
        icon: "exclamation-triangle",
      })
    );
  }
};

export const getConversationsAction = (conversations) => async (
  dispatch,
  getState
) => {
  console.log("getConversationsAction started", conversations);
  try {
    if (conversations) {
      const uid = firebase.auth().currentUser.uid;
      const newConversations = await addUserTwoToConv(conversations, uid);
      dispatch({ type: GET_CONVERSATIONS, payload: newConversations });
    }
  } catch (error) {
    console.log(error);
    dispatch(
      addToastrAction({
        type: "error",
        title: "Erreur",
        message:
          "Erreur lors de la récupération des conversations. Veuillez redémarrer l'application.",
        icon: "exclamation-triangle",
      })
    );
  }
};

const addUserTwoToConv = async (conversations, userId) => {
  async function getUserBasicInfo(uid) {
    const doc = await db.collection("users").doc(uid).get();
    if (doc.exists) {
      const user = doc.data();
      let userBasicInfo = {
        imageDisplayed: user.imageUrl,
        userType: user.userType,
        userId: doc.id,
      };
      if (user.name) {
        userBasicInfo = { ...userBasicInfo, nameDisplayed: user.name };
      } else if (user.firstName && user.lastName) {
        userBasicInfo = {
          ...userBasicInfo,
          nameDisplayed: `${user.firstName} ${user.lastName}`,
        };
      }
      return { ...userBasicInfo };
    } else return null;
  }
  const conversationsWithUserTwo = await Promise.all(
    conversations.map(async (conversation) => {
      const userTwoId = conversation.users.filter((e) => e !== userId)[0];
      const userTwo = await getUserBasicInfo(userTwoId);
      return { ...conversation, userTwo: { ...userTwo } };
    })
  );
  return conversationsWithUserTwo;
};

export const getConversationAction = (messages) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: GET_CONVERSATION, payload: messages });
  } catch (error) {
    console.log(error);
    dispatch(
      addToastrAction({
        type: "error",
        title: "Erreur",
        message:
          "Erreur lors de la récupération des messages. Veuillez redémarrer l'application.",
        icon: "exclamation-triangle",
      })
    );
  }
};
