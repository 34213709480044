import React from "react";

function Loader({ dark }) {
  return (
    <div className={dark ? "lds-ellipsis dark-ellipsis" : "lds-ellipsis"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Loader;
