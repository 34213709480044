import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModalAction } from "../../../redux/modal/modalActions";
import { setProOrderStatusAction } from "../../../redux/proOrders/proOrdersAction";
import theme from "../../styles/theme";

const Container = styled.div``;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

const Text = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.grey)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

const Button = styled.button`
  background-color: #ffffff;
  color: ${(props) => props.color};
  font-size: 18px;
  width: 150px;
  padding: 15px;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border: none;
    background-color: ${(props) => props.color};
    color: #ffffff;
  }
`;

const ValidateOrderModal = ({
  subOrderId,
  closeModalAction,
  setProOrderStatusAction,
}) => {
  return (
    <Container>
      <Title>VALIDER LA COMMANDE</Title>
      <Text>
        En validant la commande, vous vous engagez à préparer et à expédier la
        commande.
      </Text>

      <ButtonsContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            closeModalAction();
          }}
          color={theme.lightRed}
        >
          ANNULER
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            closeModalAction();
            setProOrderStatusAction(subOrderId, 3);
          }}
          color={theme.green}
        >
          VALIDER
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  closeModalAction,
  setProOrderStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateOrderModal);
