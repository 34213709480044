import { createReducer } from "./../../utils/reducerUtil";
import {
  GET_PRODUCTS,
  SET_PRODUCT,
  SET_PROMOTION,
  GET_PROMOTIONS,
  UPDATE_PRODUCT,
  UPDATE_PROMOTION,
  GET_BUYER_BASIC_INFO,
  REMOVE_PROMOTION,
  REMOVE_PRODUCT,
} from "./proConstants";

const initialState = {
  products: [],
};

export const getProducts = (state, payload) => {
  return { ...state, products: [...payload] };
};

export const setProduct = (state, payload) => {
  return { ...state, products: [...state.products, payload] };
};

export const updateProduct = (state, payload) => {
  return {
    ...state,
    products: state.products.map((e) => {
      if (e.productId === payload.productId) {
        e = payload;
      }
      return e;
    }),
  };
};

export const getPromotions = (state, payload) => {
  return { ...state, promotions: payload };
};

export const setPromotion = (state, payload) => {
  const selectedProducts = payload.products;
  const updatedProducts = [...state.products];
  updatedProducts.map((e) => {
    if (selectedProducts.includes(e.productId)) {
      e.promotion = {
        name: payload.name,
        percentage: payload.percentage,
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
    }
    return e;
  });

  return {
    ...state,
    products: updatedProducts,
    promotions: [...state.promotions, payload],
  };
};

export const updatePromotion = (state, payload) => {
  return { ...state, ...payload };
};

export const getBuyerBasicInfo = (state, payload) => {
  return { ...state, userTwo: payload };
};

export const removePromotion = (state, payload) => {
  const selectedPromotion = state.promotions.filter(
    (e) => e.promotionId === payload
  )[0];
  const newProducts = [...state.products].map((f) => {
    if (selectedPromotion.products.includes(f.productId)) {
      const { promotion, ...product } = f;
      return product;
    } else {
      return f;
    }
  });

  return {
    ...state,
    promotions: state.promotions.filter((e) => e.promotionId !== payload),
    products: newProducts,
  };
};

export const removeProduct = (state, payload) => {
  return {
    ...state,
    products: state.products.filter((e) => e.productId !== payload),
  };
};

export default createReducer(initialState, {
  [SET_PRODUCT]: setProduct,
  [GET_PRODUCTS]: getProducts,
  [SET_PROMOTION]: setPromotion,
  [GET_PROMOTIONS]: getPromotions,
  [UPDATE_PRODUCT]: updateProduct,
  [UPDATE_PROMOTION]: updatePromotion,
  [GET_BUYER_BASIC_INFO]: getBuyerBasicInfo,
  [REMOVE_PROMOTION]: removePromotion,
  [REMOVE_PRODUCT]: removeProduct,
});
