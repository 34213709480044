import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./../redux/reducer";
import thunk from "redux-thunk";

export const configureStore = (preloadedState) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = composeEnhancers(...storeEnhancers);
  const store = createStore(rootReducer, preloadedState, composedEnhancer);

  return store;
};
