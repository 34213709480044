import {
  RESET_AUTH,
  LOGOUT,
  SET_AUTH,
  AUTH_LOADING_START,
  AUTH_LOADING_END,
  AUTH_LOADING_ERROR,
} from "./authConstants";
import axios from "axios";
import { firebase, db } from "./../../utils/fbUtil";
import { addToastrAction } from "./../toastr/toastrActions";
import { getUserAction, clearUserAction } from "./../user/userActions";
import jwtDecode from "jwt-decode";

export const logInAction = (user, history) => {
  // console.log("loginAction started");
  return async (dispatch, getState) => {
    try {
      dispatch({ type: AUTH_LOADING_START });
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const data = await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
      const token = await data.user.getIdToken(true);
      const uid = data.user.uid;
      setAuthorization(token);
      dispatch(getUserAction());
      dispatch(setAuthAction(uid));
      dispatch({ type: AUTH_LOADING_END });
      dispatch(
        addToastrAction({
          type: "success",
          title: "Authentification Réussie",
          message: "Vous êtes maintenant connecté",
        })
      );
      history.push("/accueil");
    } catch (error) {
      // console.log("erreur : ", error);
      dispatch({ type: AUTH_LOADING_ERROR });
      if (error.code === "auth/wrong-password") {
        dispatch(
          addToastrAction({
            type: "error",
            title: "Echec de l'authentification",
            message:
              "Le mot de passe entré ne correspond pas avec le compte. Veuillez réessayer.",
          })
        );
      } else if (error.code === "auth/user-not-found") {
        dispatch(
          addToastrAction({
            type: "error",
            title: "Echec de l'authentification",
            message:
              "Aucun compte n'est lié à cette adresse email. Veuillez réessayer.",
          })
        );
      } else if (error.code === "auth/too-many-requests") {
        dispatch(
          addToastrAction({
            type: "error",
            title: "Echec de l'authentification",
            message:
              "Trop de tentatives infructueuses. Veuillez réessayer ulterieurement.",
          })
        );
      } else {
        dispatch(
          addToastrAction({
            type: "error",
            title: "Echec de l'authentification",
            message: "Veuillez réessayer ultérieurement",
          })
        );
      }
    }
  };
};

export const checkAuthAction = () => async (dispatch, getState) => {
  try {
    const token = localStorage.getItem("FBIdToken");
    // console.log(token, jwtDecode(token));
  } catch (error) {}
};

export const setAuthAction = (uid) => async (dispatch, getState) => {
  // console.log("set auth action started");
  dispatch({ type: SET_AUTH, payload: { uid } });
};

export const setUserAction = (user) => {
  // console.log("setUserAction started");
  return async (dispatch, getState) => {
    dispatch({ type: AUTH_LOADING_START });
    if (user && user.uid) {
      const token = await user.getIdToken(true);
      setAuthorization(token);
      dispatch(getUserAction(user.uid));
      dispatch(setAuthAction(user.uid));
      dispatch({ type: AUTH_LOADING_END });
    } else {
      dispatch(resetAuthAction());
      dispatch({ type: AUTH_LOADING_ERROR });
    }
  };
};

export const resetAuthAction = () => (dispatch, getState) => {
  // console.log("reset auth action started");
  dispatch({ type: RESET_AUTH });
};

export const logOutAction = () => {
  return async (dispatch, getState) => {
    try {
      // console.log("logoutAction started");
      await firebase.auth().signOut();
      // dispatch(resetMessengerAction());
      // dispatch(clearOrdersAction());
      dispatch(clearUserAction());
      localStorage.removeItem("FBIdToken");
      axios.defaults.headers.common["Authorization"] = "";
      dispatch(resetAuthAction());
      // dispatch(asyncActionFinish());
    } catch (error) {
      // dispatch(asyncActionError());
    }
  };
};

const setAuthorization = async (token) => {
  const FBIdToken = `Bearer ${token}`;
  try {
    localStorage.setItem("FBIdToken", FBIdToken);
    axios.defaults.headers.common["Authorization"] = FBIdToken;
  } catch (error) {
    console.log(error);
  }

  // console.log(FBIdToken);
};

export const resetPwdAction = (email) => async (dispatch, getState) => {
  try {
    if (email) {
      await firebase.auth().sendPasswordResetEmail(email);
      dispatch(
        addToastrAction({
          type: "success",
          icon: "check",
          message: "E-mail de réinitialisation de mot de passe envoyé",
          title: "Message envoyé",
        })
      );
    } else {
      const userEmail = getState().user.email;
      if (userEmail) {
        await firebase.auth().sendPasswordResetEmail(userEmail);
        dispatch(
          addToastrAction({
            type: "success",
            icon: "check",
            message: "E-mail de réinitialisation de mot de passe envoyé",
            title: "Message envoyé",
          })
        );
      } else {
        dispatch(
          addToastrAction({
            type: "error",
            icon: "exclamation-triangle",
            message: "Aucune adresse email spécifiée, veuillez réessayer",
            title: "Erreur",
          })
        );
      }
    }
  } catch (error) {
    // console.error(error);
    dispatch(
      addToastrAction({
        type: "error",
        icon: "exclamation-triangle",
        message: "Une erreur s'est produite, veuillez réessayer.",
        title: "Erreur",
      })
    );
  }
};
