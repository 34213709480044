import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { dateUtil } from "../../utils/dateUtil";
import ProductModal from "../modals/ProductModal";
import { openModalAction } from "./../../redux/modal/modalActions";

const ProductContainer = styled.div`
  min-width: ${(props) => (props.small ? "150px" : "250px")};
  width: ${(props) => (props.small ? "150px" : "250px")};
  min-height: ${(props) => (props.small ? "190px" : "290px")};
  border-radius: 7px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: block;
  height: ${(props) => (props.small ? "100px" : "200px")};
  width: ${(props) => (props.small ? "150px" : "250px")};
  background-color: #ededed;
`;

const Image = styled.img`
  display: block;
  max-height: ${(props) => (props.small ? "100px" : "200px")};
  max-width: ${(props) => (props.small ? "150px" : "250px")};
  width: auto;
  height: auto;
  margin: auto;
`;

const Title = styled.div`
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
`;

const SubTitle = styled.div`
  margin-bottom: 10px;
`;

const Text = styled.span``;

const Price = styled.span`
  color: ${(props) =>
    props.promotion ? props.theme.darkOrange : props.theme.darkGrey};
`;

const PromoIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.darkOrange};
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PromoIcon = styled.i`
  color: #ffffff;
  font-size: 18px;
`;

const ProductItem = ({ product, openModalAction, small }) => {
  const [promo, setPromotion] = useState(false);
  useEffect(() => {
    const todaysDate = new Date();
    if (product.promotion) {
      const startDate = dateUtil(product.promotion.startDate);
      const endDate = dateUtil(product.promotion.endDate);
      if (startDate <= todaysDate && endDate >= todaysDate) {
        setPromotion(true);
      }
    }
  }, []);
  const modal = {
    modalType: "product",
    modalContent: product,
  };
  return (
    <ProductContainer
      small={small ? true : false}
      onClick={() => openModalAction(modal)}
    >
      <ImageContainer small={small ? true : false}>
        {promo && (
          <PromoIconContainer>
            <PromoIcon className="fas fa-percent" />
          </PromoIconContainer>
        )}
        <Image small={small ? true : false} src={product.mainImage}></Image>
      </ImageContainer>

      <Title>{product.name}</Title>
      <SubTitle>
        <Text>{product.quantity}</Text> -{" "}
        <Price promotion={promo}>
          {promo
            ? (product.unitPrice * (1 - product.promotion.percentage / 100))
                .toFixed(2)
                .replace(".", ",")
            : product.unitPrice}{" "}
          €
        </Price>
      </SubTitle>
    </ProductContainer>
  );
};

// ProductItem.propTypes = {
//   props: PropTypes,
// };

const mapState = (state, props) => ({});

const mapActions = {
  openModalAction,
};

export default connect(mapState, mapActions)(ProductItem);
