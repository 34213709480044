import { Formik, Form } from "formik";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModalAction } from "../../redux/modal/modalActions";
import ProductPickerItem from "../components/Form/ProductPickerItem";
import TextInput from "../components/Form/TextInput";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { setPromotionsAction } from "../../redux/pro/proActions";
import * as Yup from "yup";
setDefaultLocale("fr", fr);
registerLocale("fr", fr);

const Container = styled.div`
  padding-top: 10px;
`;

const ProductPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const DateLabel = styled.label`
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const DateInput = styled.div`
  .react-datepicker__input-container {
    input: {
      font-size: 15px;
      padding: 10px;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
    }
  }
`;

const ProductPickerTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const AddPromotionButton = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.green};
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  outline: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  i {
    margin-right: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

function PromotionForm({ products, setPromotionsAction, closeModalAction }) {
  return (
    <Container>
      <Formik
        initialValues={{
          startDate: new Date(),
          endDate: new Date(),
          name: "",
          percentage: "",
          products: [],
        }}
        onSubmit={(values) => {
          setPromotionsAction(values);
          closeModalAction();
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Nom de promotion requis"),
          percentage: Yup.number()
            .typeError(
              "Le pourcentage doit être un nombre entre 1 et 100, sans virgules"
            )
            .required("Pourcentage de réduction requis")
            .positive("Le pourcentage doit être positif (entre 1 et 100)")
            .integer("Doit être un nombre entier"),
        })}
      >
        {({ setFieldValue, values, errors, handleChange, touched }) => (
          <Form>
            <TextInput
              error={touched.name && errors.name}
              onChange={handleChange}
              value={values.name}
              name="name"
              label="Nom de la promotion"
              placeholder="Ex : Soldes d'été"
            />
            <TextInput
              error={touched.percentage && errors.percentage}
              onChange={handleChange}
              value={values.percentage}
              name="percentage"
              label="Pourcentage de réduction"
              placeholder="Ex : 20"
            />

            <DateInput>
              <DateLabel>Date de début de la promotion :</DateLabel>
              <DatePicker
                locale="fr-FR"
                selected={values.startDate}
                onChange={(date) => setFieldValue("startDate", date)}
              />
            </DateInput>

            <DateInput>
              <DateLabel>Date de fin de la promotion :</DateLabel>
              <DatePicker
                locale="fr-FR"
                selected={values.endDate}
                onChange={(date) => setFieldValue("endDate", date)}
              />
            </DateInput>
            <ProductPickerTitle>Sélectionner les produits :</ProductPickerTitle>
            <ProductPickerContainer>
              {products &&
                products.map((e) => (
                  <ProductPickerItem
                    product={e}
                    products={values.products}
                    action={setFieldValue}
                    key={e.productId}
                  />
                ))}
            </ProductPickerContainer>
            <AddPromotionButton>
              <i className="fal fa-plus" />
              Ajouter la promotion
            </AddPromotionButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const mapState = (state, props) => ({
  products: state.pro.products,
});

const mapActions = {
  setPromotionsAction,
  closeModalAction,
};

export default connect(mapState, mapActions)(PromotionForm);
