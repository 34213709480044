import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
  background-color: #fafafa;
  &:hover {
    filter: brightness(0.9);
  }
`;

const Image = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 35px;
  @media (max-width: 800px) {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 500;
  font-size: 15px;
`;

const ConversationInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const LastMessage = styled.span`
  margin-top: 10px;
  font-size: 13px;
`;

export const ConversationItem = ({ conversation, action, selected }) => {
  const displayedLastMessage =
    conversation.lastMessage.length > 40
      ? conversation.lastMessage.substring(0, 40) + "..."
      : conversation.lastMessage;
  return (
    <Container
      onClick={action}
      style={{ backgroundColor: selected ? "#ededed" : "#ffffff" }}
    >
      <Image src={conversation.userTwo.imageDisplayed} />
      <ConversationInfo>
        <Name>{conversation.userTwo.nameDisplayed}</Name>
        <LastMessage>{displayedLastMessage}</LastMessage>
      </ConversationInfo>
    </Container>
  );
};

// ConversationItem.propTypes = {
//     props: PropTypes
// }

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationItem);
