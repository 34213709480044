import React from "react";
// eslint-disable-next-line
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = ({ component: Component, auth, ...rest }) => {
  // const { isAuthenticated } = auth;
  const isAuthenticated = true;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

AuthRoute.propTypes = {};

const mapState = (state) => ({
  auth: state.auth,
});

export default connect(mapState)(AuthRoute);
