import React from "react";
import PropTypes from "prop-types";

function Orders(props) {
  return <div>Orders Page</div>;
}

Orders.propTypes = {};

export default Orders;
