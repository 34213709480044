import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import ProductItem from "../components/ProductItem";
import { connect } from "react-redux";
import { openModalAction } from "../../redux/modal/modalActions";
import { addToastrAction } from "../../redux/toastr/toastrActions";

const Container = styled.div`
  padding: 20px;
`;

const Icon = styled.i`
  color: #fff;
  font-size: 22px;
  margin-right: 10px;
`;

const AddProductButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: ${(props) => props.theme.franceBlue};
  border-radius: 5px;
  border: none;
  outline: 0;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${(props) => props.theme.marineBlue};
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  width: 100vw;
  max-width: 100%;
  height: 70vh;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.grey};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NoProductsDiv = styled.div`
  width: 80%;
  color: ${(props) => props.theme.marineBlue};
  background-color: #eaeaea;
  text-align: center;
  font-size: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;
function Products({
  products,
  openModalAction,
  stripeConnected,
  addToastrAction,
}) {
  const modal = {
    modalTitle: "Ajouter un produit",
    modalType: "add-product",
  };
  return (
    <Container>
      <AddProductButton
        onClick={(e) => {
          if (stripeConnected) {
            openModalAction(modal);
          } else {
            addToastrAction({
              type: "warning",
              title: "Erreur",
              message:
                'Veuillez configurer Stripe dans "Administration Paiements" puis réessayer',
            });
          }
        }}
      >
        <Icon className="fal fa-plus" />
        Ajouter un produit
      </AddProductButton>
      <Text>Mes Produits</Text>
      <ProductsContainer>
        {products ? (
          products.map((e) => <ProductItem product={e} key={e.productId} />)
        ) : (
          <NoProductsDiv>
            Vous n'avez pas encore ajouté de produit
          </NoProductsDiv>
        )}
      </ProductsContainer>
    </Container>
  );
}

// Products.propTypes = {};

const mapState = (state, props) => ({
  products: state.pro.products,
  stripeConnected: state.user.stripeConnected,
});

const mapActions = {
  openModalAction,
  addToastrAction,
};

export default connect(mapState, mapActions)(Products);
