import { createReducer } from "./../../utils/reducerUtil";
import { ADD_TOASTR, REMOVE_TOASTR } from "./toastrConstants";

const initialState = {
  toastrArr: [],
};

const addToastr = (state, payload) => {
  return { ...state, toastrArr: [...state.toastrArr, payload] };
};

const removeToastr = (state, payload) => {
  return {
    ...state,
    toastrArr: state.toastrArr.filter((e) => e.toastrId !== payload),
  };
};
export default createReducer(initialState, {
  [ADD_TOASTR]: addToastr,
  [REMOVE_TOASTR]: removeToastr,
});
