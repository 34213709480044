import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { setOrdersFilterAction } from "../../redux/proOrders/proOrdersAction";

const OrdersSelector = styled.div`
  max-width: 530px;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
`;

const SelectorButton = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;
  i  {
  }
  span {
    margin-top: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const RadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid #165dbf;
  margin-top: 10px;
`;

const RadioSelected = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #165dbf;
`;

const OrdersProSelector = ({ filterType, setOrdersFilterAction }) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    if (filterType !== selected) {
      setSelected(filterType);
    }
  });
  return (
    <OrdersSelector>
      <SelectorButton onClick={() => setOrdersFilterAction("active")}>
        <i className="fal fa-box" />
        <span>En cours</span>
        <RadioButton>{selected === "active" && <RadioSelected />}</RadioButton>
      </SelectorButton>
      <SelectorButton onClick={() => setOrdersFilterAction("valid")}>
        <i className="fal fa-user-check" />
        <span>À valider</span>
        <RadioButton>{selected === "valid" && <RadioSelected />}</RadioButton>
      </SelectorButton>
      <SelectorButton onClick={() => setOrdersFilterAction("dispute")}>
        <i className="fal fa-balance-scale" />
        <span>En litige</span>
        <RadioButton>{selected === "dispute" && <RadioSelected />}</RadioButton>
      </SelectorButton>
      <SelectorButton onClick={() => setOrdersFilterAction("past")}>
        <i className="fal fa-box-check" />
        <span>Terminées</span>
        <RadioButton>{selected === "past" && <RadioSelected />}</RadioButton>
      </SelectorButton>
    </OrdersSelector>
  );
};

// OrdersProSelector.propTypes = {
//   props: PropTypes,
// };

const mapStateToProps = (state) => ({
  filterType: state.proOrders.filterType,
});

const mapDispatchToProps = {
  setOrdersFilterAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersProSelector);
