import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const ToastrItem = styled.div`
  position: relative;
  z-index: 100;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 180px;
  min-height: 80px;
  margin-top: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const CloseIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
`;

const ToastrMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  margin-right: 10px;
  color: #fff;
  i {
    font-size: 27px;
    margin-right: 20px;
  }
`;

const Title = styled.span`
  display: inline-block;
  color: #fff;
  font-size: 800;
`;

const Message = styled.span`
  display: block;
  color: #fff;
  margin-top: 5px;
  font-size: 14px;
`;

function Toastr({ type, title, message, close }) {
  let selectedIcon;
  let bgColor = "rgba(22, 191, 70, 1)";
  if (type === "error") {
    bgColor = "rgba(196, 47, 59, 1)";
    selectedIcon = "fal fa-exclamation-triangle";
  } else if (type === "success") {
    bgColor = "rgba(22, 191, 70, 1)";
    selectedIcon = "fal fa-check";
  } else if (type === "warning") {
    bgColor = "rgba(247, 181, 0, 1)";
    selectedIcon = "fal fa-exclamation-triangle";
  }
  return (
    <ToastrItem style={{ backgroundColor: bgColor }}>
      <CloseIcon onClick={close} className="far fa-times" />
      <ToastrMessageContainer>
        <i className={selectedIcon} />
        <div style={{ width: 150, height: "100%" }}>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </div>
      </ToastrMessageContainer>
    </ToastrItem>
  );
}

const mapState = (state, props) => ({});

const mapActions = {};

export default connect(mapState, mapActions)(Toastr);
