import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB1Nu3YyO28E5pIyg6HfRc3vetZx3-8fUk",
  authDomain: "la-chariotte.firebaseapp.com",
  databaseURL: "https://la-chariotte.firebaseio.com",
  projectId: "la-chariotte",
  storageBucket: "la-chariotte.appspot.com",
  messagingSenderId: "1095594584646",
  appId: "1:1095594584646:web:9340ef3fb0403e7c92f847",
  measurementId: "G-LV2EHYS8D0",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const db = firebase.firestore();

export { db, firebase };
