import React from "react";
import PropTypes from "prop-types";
import Text from "../components/Text";
import HomeItem from "../components/HomeItem";
import theme from "../styles/theme";
import { connect } from "react-redux";
import styled from "styled-components";
import { calculateSales } from "../../utils/ordersUtil";
import { setOrdersFilterAction } from "../../redux/proOrders/proOrdersAction";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 30px;
`;

function Dashboard({
  productsNb,
  ordersNb,
  ordersToValidateNb,
  disputeNb,
  promotionsNb,
  orders,
  products,
  setOrdersFilterAction,
}) {
  let salesNb = 0;
  if (orders && products) {
    if (orders.length > 0 && products.length > 0) {
      const todaysDate = new Date();
      const salesArr = calculateSales(orders, products);
      console.log(salesArr);
      salesArr.forEach((e) => {
        if (e.month === todaysDate.getMonth()) {
          salesNb = salesNb + parseFloat(e.price);
        }
      });
    }
  }
  return (
    <Container>
      {disputeNb && disputeNb > 0 && (
        <HomeItem
          to="/commandes"
          action={() => setOrdersFilterAction("dispute")}
          color={theme.lightRed}
          textColor={theme.white}
          text="Litiges en cours"
          nb={disputeNb}
        />
      )}
      <HomeItem
        to="/commandes"
        action={() => setOrdersFilterAction("valid")}
        color={theme.blue}
        textColor={theme.white}
        text="Commandes à valider"
        nb={ordersToValidateNb}
      />
      <HomeItem
        to="/rapport"
        color={theme.white}
        textColor={theme.grey}
        text="Chiffre d'Affaires ce mois"
        nb={`${salesNb.toFixed(2).replace(".", ",")} €`}
      />
      <HomeItem
        to="/commandes"
        action={() => setOrdersFilterAction("all")}
        color={theme.white}
        textColor={theme.grey}
        text="Commandes reçues"
        nb={ordersNb}
      />
      <HomeItem
        to="/produits"
        color={theme.franceBlue}
        textColor={theme.white}
        text="Produit(s) en ligne"
        nb={productsNb}
      />
      <HomeItem
        to="/promotions"
        color={theme.orange}
        textColor={theme.grey}
        text="Promotions"
        nb={promotionsNb}
      />
    </Container>
  );
}

Dashboard.propTypes = {};

const mapState = (state, props) => ({
  productsNb: state.pro.products.length,
  ordersNb: state.proOrders.ordersList.length,
  ordersToValidateNb: state.proOrders.ordersList.filter((e) => e.status === 2)
    .length,
  disputeNb: state.proOrders.ordersList.filter((e) => e.status === 7).length,
  orders: state.proOrders.ordersList,
  products: state.pro.products,
  promotionsNb: state.pro.promotions.length,
});

const mapActions = {
  setOrdersFilterAction,
};

export default connect(mapState, mapActions)(Dashboard);
