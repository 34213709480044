import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { addToastrAction } from "../../redux/toastr/toastrActions";
import { validateTermsAction } from "../../redux/user/userActions";

const PDFButton = styled.a`
  display: inline-block;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.lightRed};
  color: ${(props) => props.theme.lightRed};
  background-color: #fff;
  font-size: 17px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  i {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${(props) => props.theme.lightRed};
    color: #fff;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  input {
    margin-right: 10px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.franceBlue};
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

function TermsAndConditions({ validateTermsAction, addToastrAction }) {
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const onValidateTerms = () => {
    if (terms && policy) {
      validateTermsAction();
    } else {
      addToastrAction({
        type: "error",
        title: "Conditions non acceptées",
        message:
          "Vous devez accepter les conditions avant de pouvoir utiliser la plateforme",
      });
    }
  };
  return (
    <div>
      <PDFButton
        href="https://drive.google.com/file/d/1roP1D0xAJx3MIt2TjkX0LGsDvbGWwDiE/view"
        target="_blank"
      >
        <i className="fal fa-file-pdf" />
        CGU / CGV
      </PDFButton>
      <CheckboxContainer>
        <input
          type="checkbox"
          value={terms}
          onChange={() => setTerms(!terms)}
          id="termsCheck"
        />
        <label htmlFor="termsCheck">J'ai lu et j'accepte les CGU / CGV *</label>
      </CheckboxContainer>
      <PDFButton
        href="https://drive.google.com/file/d/1dA_-UcIflBDIgNnZ-LdguvJ6yvhJLrQm/view"
        target="_blank"
      >
        <i className="fal fa-file-pdf" />
        Politique de confidentialité
      </PDFButton>
      <CheckboxContainer>
        <input
          type="checkbox"
          value={policy}
          onChange={() => setPolicy(!policy)}
          id="policyCheck"
        />
        <label htmlFor="policyCheck">
          J'ai lu et j'accepte la politique de confidentialité *
        </label>
      </CheckboxContainer>
      <SubmitButton onClick={() => onValidateTerms()}>
        <i className="fal fa-check" />
        J'accepte les conditions
      </SubmitButton>
    </div>
  );
}

const mapState = (state, props) => ({});

const mapActions = {
  addToastrAction,
  validateTermsAction,
};

export default connect(mapState, mapActions)(TermsAndConditions);
