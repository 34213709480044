import React from "react";
import styled from "styled-components";
import Toastr from "./Toastr";
import { removeToastrAction } from "./../../redux/toastr/toastrActions";
import { connect } from "react-redux";

const ToastrDiv = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column-reverse;
`;

function ToastrContainer({ removeToastrAction, toastrs }) {
  return (
    <ToastrDiv>
      {toastrs &&
        toastrs.map((e) => (
          <Toastr
            key={e.toastrId}
            toastrId={e.toastrId}
            type={e.type}
            message={e.message}
            title={e.title}
            icon={e.icon}
            close={() => removeToastrAction(e.toastrId)}
          />
        ))}
    </ToastrDiv>
  );
}

const mapState = (state, props) => ({
  toastrs: state.toastr.toastrArr,
});

const mapActions = {
  removeToastrAction,
};

export default connect(mapState, mapActions)(ToastrContainer);
