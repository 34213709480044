import colors from "./../layout/styles/theme";
import { dateUtil, toTimestamp } from "./dateUtil";

export const statusOrdersUtil = [
  {
    status: 1,
    text: "Commande effectuée",
    short: "Commandée",
    labelColor: colors.orange,
  },
  {
    status: 2,
    text: "Commande payée",
    short: "Payée",
    labelColor: colors.orange,
  },
  {
    status: 3,
    text: "Commande validée",
    short: "Validée",
    labelColor: colors.green,
  },
  {
    status: 4,
    text: "Commande annulée et remboursée",
    short: "Annulée",
    labelColor: colors.grey,
  },
  {
    status: 5,
    text: "Commande expédiée",
    short: "Expédiée",
    labelColor: colors.lighterGreen,
  },
  {
    status: 6,
    text: "Commande terminée",
    short: "Terminée",
    labelColor: colors.mediumGrey,
  },
  {
    status: 7,
    text: "Litige en cours",
    short: "En Litige",
    labelColor: colors.red,
  },
  {
    status: 8,
    text: "Litige résolu",
    short: "Terminée",
    labelColor: colors.lightGrey,
  },
  {
    status: 9,
    text: "Commande retournée",
    short: "Retournée",
    labelColor: colors.orange,
  },
  {
    status: 10,
    text: "Commande remboursée", // Cause : litige
    short: "Remboursée",
    labelColor: colors.lightGrey,
  },
  {
    status: 11,
    text: "Commande ré-expédiée",
    short: "Remboursée",
    labelColor: colors.lighterGreen,
  },
  {
    status: 12,
    text: "Commande annulée et remboursée", // Par le particulier
    short: "Annulée",
    labelColor: colors.grey,
  },
  {
    status: 13,
    text: "Commande retournée et remboursée",
    short: "Remboursée",
    labelColor: colors.lightGrey,
  },
];

export const orderData = [
  {
    orderId: "1234",
    status: 3,
    createdAt: toTimestamp(new Date()),
    orderNumber: "#000001",
  },
  {
    orderId: "1235",
    status: 4,
    createdAt: toTimestamp(new Date()),
    orderNumber: "#000002",
  },
  {
    orderId: "1236",
    status: 5,
    createdAt: toTimestamp(new Date()),
    orderNumber: "#000003",
  },
  {
    orderId: "1237",
    status: 7,
    createdAt: toTimestamp(new Date()),
    orderNumber: "#000004",
  },
];

export const toOrderNumber = (nb) => {
  if (parseInt(nb) < 10) {
    return "#000000" + nb.toString();
  } else if (parseInt(nb) >= 10 && parseInt(nb) < 100) {
    return "#00000" + nb.toString();
  } else if (parseInt(nb) >= 100 && parseInt(nb) < 1000) {
    return "#0000" + nb.toString();
  } else if (parseInt(nb) >= 1000 && parseInt(nb) < 10000) {
    return "#000" + nb.toString();
  } else if (parseInt(nb) >= 10000 && parseInt(nb) < 100000) {
    return "#00" + nb.toString();
  } else if (parseInt(nb) >= 100000 && parseInt(nb) < 1000000) {
    return "#0" + nb.toString();
  } else if (parseInt(nb) >= 1000000 && parseInt(nb) < 10000000) {
    return "#" + nb.toString();
  }
};

export const calculateSales = (orders) => {
  if (orders) {
    const ordersPrices = orders.map((e) => {
      const date = dateUtil(e.createdAt);
      const month = date.getMonth();
      let price = 0;
      if (
        e.status !== 4 &&
        e.status !== 10 &&
        e.status !== 12 &&
        e.status !== 13
      ) {
        e.products.forEach((f) => {
          price = e.transfer.amount / 100;
        });
        return {
          orderNumber: e.orderNumber,
          price: price.toFixed(2),
          month: month,
        };
      } else if (e.status === 12 || e.status === 13) {
        return {
          orderNumber: e.orderNumber,
          price: 0,
          month: month,
        };
      } else {
        e.products.forEach((f) => {
          price = e.transfer.amount / 100;
        });
        return {
          orderNumber: e.orderNumber,
          price: (-(price * 0.014 + 0.25).toFixed(2)).toLocaleString("fr-FR"),
          month: month,
        };
      }
    });
    // console.log(ordersPrices);
    return ordersPrices;
  }
};
