import React, { useEffect, useState, useRef } from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { db, firebase } from "./../../utils/fbUtil";
import { connect } from "react-redux";
import {
  getConversationsAction,
  getConversationAction,
  sendMessageAction,
} from "../../redux/messenger/messengerActions";
import { ConversationItem } from "../components/ConversationItem";
import Loader from "../components/Loader";
import MessageItem from "../components/MessageItem";

const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const LeftPanel = styled.div`
  height: calc(100vh - 70px);
  width: 300px;
  min-width: 300px;
  background-color: #fafafa;
  overflow-y: scroll;
  border-right: 1px solid #dadada;
  @media (max-width: 800px) {
    width: 250px;
    min-width: 250px;
  }
`;

const RightPanel = styled.div`
  width: 100%;
  padding-left: 20px;
  height: 100%;
  overflow-y: scroll;
`;

const MessagesContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
`;

const MessengerInput = styled.form`
  position: fixed;
  left: 310px;
  bottom: 10px;
  height: 40px;
  width: calc(100vw - 320px);
  display: flex;
  align-items: center;

  input {
    width: calc(100% - 50px);
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #dadada;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: 0;
  }
  button {
    padding: 9px;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.blue};
    background-color: #fff;
    color: ${(props) => props.theme.blue};
    &:hover {
      background-color: ${(props) => props.theme.blue};
      color: #fff;
    }
  }
  i {
    font-size: 20px;
  }
`;

function Messenger({
  getConversationsAction,
  conversations,
  getConversationAction,
  messages,
  uid,
  sendMessageAction,
}) {
  const [messageText, setMessageText] = useState("");
  const [convSelected, setConvSelected] = useState(null);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const unsubscribe = db
      .collection("conversations")
      .where("users", "array-contains", firebase.auth().currentUser.uid)
      .orderBy("lastMessageAt", "desc")
      .onSnapshot((snapshot) => {
        const conversations = [];
        if (snapshot.size) {
          snapshot.forEach((doc) =>
            conversations.push({ ...doc.data(), conversationId: doc.id })
          );
          getConversationsAction(conversations);
          // if lastMessageSenderId !== uid, notification
        }
      });

    return () => {
      unsubscribe();
    };
  }, []);

  const onConversationOpen = async (conversationId) => {
    setConvSelected(conversationId);
    const unsubscribe = db
      .collection("conversations")
      .doc(conversationId)
      .collection("messages")
      .orderBy("createdAt")
      .onSnapshot((snapshot) => {
        const messages = [];
        if (snapshot.size) {
          snapshot.forEach((doc) =>
            messages.push({ ...doc.data(), messageId: doc.id })
          );
          getConversationAction(messages);
        }
      });
    if (messages) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return () => {
      unsubscribe();
    };
  };

  if (conversations) {
    return (
      <Container>
        <LeftPanel>
          {conversations.map((e) => (
            <ConversationItem
              selected={e.conversationId === convSelected}
              key={e.conversationId}
              conversation={e}
              action={() => onConversationOpen(e.conversationId)}
            />
          ))}
        </LeftPanel>

        <RightPanel>
          {messages && (
            <>
              <MessagesContainer>
                {messages &&
                  messages.map((e, i) => (
                    <MessageItem message={e} uid={uid} key={e.messageId} />
                  ))}
                <div ref={messagesEndRef} />
              </MessagesContainer>
              <MessengerInput>
                <input
                  type="text"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onFocus={() =>
                    messagesEndRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                />
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    sendMessageAction(convSelected, messageText);
                    setMessageText("");
                  }}
                >
                  <i className="fal fa-paper-plane" />
                </button>
              </MessengerInput>
            </>
          )}
        </RightPanel>
      </Container>
    );
  } else {
    return (
      <LoaderDiv>
        <Loader dark />
      </LoaderDiv>
    );
  }
}

Messenger.propTypes = {};

const mapState = (state, props) => ({
  conversations: state.messenger.conversations,
  messages: state.messenger.messages,
  uid: state.user.uid,
});

const mapActions = {
  getConversationsAction,
  getConversationAction,
  sendMessageAction,
};

export default connect(mapState, mapActions)(Messenger);
