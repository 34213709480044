import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Link = styled(NavLink)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Div = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  width: 22%;
  min-height: 200px;
  border-radius: 7px;
  background-color: ${(props) => props.color};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
`;

const ExpandIcon = styled.i`
  color: ${(props) => props.textColor};
`;

const PrimaryText = styled.span`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.textColor};
`;

const SecondaryText = styled.span`
  color: ${(props) => props.textColor};
  font-size: 35px;
  font-weight: 500;
  margin-top: 20px;
  i {
    margin-right: 20px;
    font-size: 30px;
  }
`;

const HomeItem = ({ color, textColor, to, text, nb, action }) => (
  <Div color={color}>
    <PrimaryText textColor={textColor}>{text}</PrimaryText>
    <SecondaryText textColor={textColor}>
      {text === "Litiges en cours" && (
        <i className="fal fa-exclamation-triangle" />
      )}
      {nb}
    </SecondaryText>
    <Link to={to} onClick={action}>
      <ExpandIcon textColor={textColor} className="far fa-expand-alt" />
    </Link>
  </Div>
);

// HomeItem.propTypes = {
//   props: PropTypes,
// };

export default HomeItem;
