import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { toMonth } from "../../utils/dateUtil";
import { calculateSales } from "../../utils/ordersUtil";
import SalesItem from "../components/SalesItem";

const Container = styled.div`
  max-height: calc(100vh - 70px);
`;

const MonthSelector = styled.div`
  position: fixed;
  top: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 25px;
  height: 85px;
  background-color: #f0f0f0;
`;

const Button = styled.button`
  outline: 0;
  cursor: pointer;
  font-size: 25px;
  background: none;
  border: none;
  &:hover {
    color: ${(props) => props.theme.franceBlue};
  }
`;

const Month = styled.div`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
`;

const OrdersSalesContainer = styled.div`
  margin-top: 85px;
  background-color: #ffffff;
  height: calc(100vh - 240px);
  width: 100%:
`;

const TotalRevenues = styled.div`
  background-color: ${(props) => props.theme.franceBlue};
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  color: #ffffff;
  font-size: 25px;
`;

const SalesPro = ({ orders }) => {
  console.log({ orders });
  const [date, setDate] = useState(new Date());
  const changeMonth = (e) => {
    if (e === "next") {
      setDate(new Date(date.setMonth(date.getMonth() + 1)));
    } else {
      setDate(new Date(date.setMonth(date.getMonth() - 1)));
    }
  };
  const salesArr = calculateSales(orders);
  let monthlySales = 0;
  if (salesArr) {
    salesArr.forEach((e) => {
      if (e.month === date.getMonth()) {
        monthlySales = monthlySales + parseFloat(e.price);
      }
    });
  }

  console.log(salesArr);
  return (
    <Container>
      <MonthSelector>
        <Button onClick={() => changeMonth("prev")}>
          <i className="fal fa-chevron-left" />
        </Button>
        <Month>
          {toMonth(date.getMonth())} {date.getFullYear()}
        </Month>
        <Button onClick={() => changeMonth("next")}>
          <i className="fal fa-chevron-right" />
        </Button>
      </MonthSelector>
      <OrdersSalesContainer>
        {salesArr &&
          salesArr
            .filter((f) => f.month === date.getMonth())
            .map((e) => <SalesItem key={e.orderNumber} order={e} />)}
      </OrdersSalesContainer>
      <TotalRevenues>
        <span>TOTAL</span>
        <span>{monthlySales.toFixed(2).replace(".", ",")} €</span>
      </TotalRevenues>
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  orders: state.proOrders.ordersList,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(SalesPro);
