export const toPhoneString = (phoneNb) => {
  const p = phoneNb.split("");
  let pStr = "";
  for (let i = 0; i < p.length; i++) {
    if (i > 0 && (i + 1) % 2 === 0) {
      pStr = pStr + `${p[i]} `;
    } else if (i >= 0 && (i + 1) % 2 !== 0) {
      pStr = pStr + p[i];
    }
  }
  return pStr;
};
