import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../styles/theme";
import Shop from "../components/Shop";
import axios from "axios";
import { connect } from "react-redux";
import { openModalAction } from "./../../redux/modal/modalActions";
import { logOutAction } from "./../../redux/auth/authActions";
import Loader from "../components/Loader";

const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 70px);
  overflow: auto;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
`;

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-right: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  background-color: ${(props) => props.color};
  color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  i {
    margin-right: 5px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const ShopContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid ${(props) => props.theme.lighterGrey};
`;

function ShopPro({ openModalAction, logOutAction, userEmail }) {
  const [stripeLoading, setStripeLoading] = useState(false);
  const getStripeAccess = async () => {
    setStripeLoading(true);
    try {
      const res = await axios.get(
        "https://europe-west1-la-chariotte.cloudfunctions.net/api/stripe"
      );
      window.open(res.data, "_blank");
      setStripeLoading(false);
    } catch (error) {
      console.log(error);
      setStripeLoading(false);
    }
  };
  return (
    <Container>
      {/* Administration Paiements */}
      <Title>GESTION</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button color={theme.marineBlue} onClick={() => getStripeAccess()}>
          {stripeLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <i className="fal fa-user-cog" />
              Administration paiements
            </>
          )}
        </Button>
        {/* Modifier le profil */}
        <Button
          color={theme.franceBlue}
          onClick={() =>
            openModalAction({
              modalTitle: "Modifier le profil",
              modalType: "edit-pro-profile",
            })
          }
        >
          <i className="fal fa-edit" />
          Modifier le profil
        </Button>
        {/* Se Déconnecter */}
        <Button
          color={theme.mediumGrey}
          onClick={() =>
            openModalAction({
              modalType: "reset-password",
              modalContent: userEmail,
            })
          }
        >
          <i className="fal fa-lock-alt" />
          Changer le mot de passe
        </Button>
        <Button color={theme.lightRed} onClick={() => logOutAction()}>
          <i className="fal fa-sign-out" />
          Se déconnecter
        </Button>
      </div>

      {/* Shop */}
      <ShopContainer>
        <Shop />
      </ShopContainer>
    </Container>
  );
}

ShopPro.propTypes = {};

const mapState = (state, props) => ({
  userEmail: state.user.email,
});

const mapActions = {
  openModalAction,
  logOutAction,
};

export default connect(mapState, mapActions)(ShopPro);
