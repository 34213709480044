import React from "react";
import { Field } from "formik";
import styled from "styled-components";

const Container = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  margin-bottom: 10px;
  width: ${(props) => (props.fullwidth ? "100%" : "auto")};
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled(Field)`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.lightRed : "#dfdfdf")};
  width: 100%;
  font-size: 13px;
`;

const ErrorSpan = styled.span`
  font-size: 12px;
  display: block;
  color: ${(props) => props.theme.lightRed};
  font-style: italic;
`;

function TextInput({ name, label, value, onChange, placeholder, type, error }) {
  return (
    <Container fullwidth>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input
        type={type ? type : "text"}
        id={name}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </Container>
  );
}

export default TextInput;
