import React, { useState } from "react";
import styled from "styled-components";
import Compress from "browser-image-compression";
import { addToastrAction } from "../../../redux/toastr/toastrActions";
import { connect } from "react-redux";

const Container = styled.div`
  position: relative;
  width: ${(props) => (props.imageSize ? props.imageSize : "200px")};
  margin-right: 10px;
`;

const FileInput = styled.input`
  color: transparent;
  display: none;
`;

const ProductImage = styled.img`
  display: block;
  width: ${(props) => (props.imageSize ? props.imageSize : "200px")};
  height: ${(props) => (props.imageSize ? props.imageSize : "200px")};
  border-radius: ${(props) => (props.round ? "50%" : "7px")};
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
  object-fit: cover;
`;

const ImagePlaceholder = styled.label`
  cursor: pointer;
  display: flex;
  width: ${(props) => (props.imageSize ? props.imageSize : "200px")};
  height: ${(props) => (props.imageSize ? props.imageSize : "200px")};
  background-color: #dfdfdf;
  border-radius: ${(props) => (props.round ? "50%" : "7px")};
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: brightness(0.9);
  }
`;

const CameraIcon = styled.i`
  font-size: ${(props) =>
    props.imageSize ? props.imageSize.split("px")[0] / 4 + "px" : "40px"};
`;

const RemoveIcon = styled.i`
  color: ${(props) => props.theme.lightRed};
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.lightRed};
  }
`;

function ImageInput({
  setFieldValue,
  name,
  value,
  imageSize,
  round = false,
  addToastrAction,
}) {
  const onImageChange = async (e) => {
    const image = e.target.files[0];

    if (image.type.startsWith("image/")) {
      const options = {
        maxSizeMB: 0.6,
        useWebWorker: true,
        maxWidthOrHeight: 800,
      };

      // Initialize compression
      Compress(image, options)
        .then((compressedBlob) => {
          console.log(compressedBlob);

          // Adding Date to blob before conversion
          compressedBlob.lastModifiedDate = new Date();

          // Convert the blob to file
          const convertedBlobFile = new File([compressedBlob], image.name, {
            type: image.type,
            lastModified: Date.now(),
          });
          // console.log(convertedBlobFile);
          setFieldValue(name, convertedBlobFile);
          // Here you are free to call any method you are gonna use to upload your file example uploadToCloudinaryUsingPreset(convertedBlobFile)
        })
        .catch((e) => {
          // Show the user a toast message or notification that something went wrong while compressing file
        });
    } else {
      addToastrAction({
        type: "error",
        title: "Erreur",
        message: "Le fichier n'est pas une image. Veuillez réessayer.",
      });
    }
    // Compression config
  };
  const onImageClear = (e) => {
    setFieldValue(name, "");
  };
  if (name)
    return (
      <>
        <Container imageSize={imageSize}>
          {value && value !== "" && (
            <RemoveIcon
              className="fal fa-trash-alt"
              onClick={() => onImageClear()}
            />
          )}
          {value && value !== "" ? (
            <ProductImage
              round={round}
              imageSize={imageSize}
              src={
                typeof value === "string" && value.startsWith("https://")
                  ? value
                  : URL.createObjectURL(value)
              }
            />
          ) : (
            <ImagePlaceholder
              imageSize={imageSize}
              htmlFor={name}
              round={round}
            >
              <CameraIcon imageSize={imageSize} className="fal fa-camera" />
            </ImagePlaceholder>
          )}
        </Container>

        {value && value === "" ? (
          <FileInput
            type="file"
            onChange={(e) => onImageChange(e)}
            name={name}
            id={name}
            value=""
          />
        ) : (
          <FileInput
            type="file"
            onChange={(e) => onImageChange(e)}
            name={name}
            id={name}
          />
        )}
      </>
    );
}

const mapState = (state, props) => ({});

const mapActions = {
  addToastrAction,
};

export default connect(mapState, mapActions)(ImageInput);
