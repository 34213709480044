import {
  OPEN_MODAL,
  CLOSE_MODAL,
  GO_BACK_MODAL,
  ADD_STACK_MODAL,
} from "./modalConstants";

export const openModalAction = (modal) => (dispatch) => {
  return dispatch({ type: OPEN_MODAL, payload: modal });
};

export const closeModalAction = () => (dispatch) => {
  dispatch({ type: CLOSE_MODAL });
};

export const goBackModalAction = () => (dispatch) => {
  dispatch({ type: GO_BACK_MODAL });
};

export const addStackModalAction = (modal) => (dispatch) => {
  dispatch({ type: ADD_STACK_MODAL, payload: modal });
};
