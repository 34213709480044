export default {
  blue: "#0091FF",
  grey: "#040404",
  mediumGrey: "#545454",
  lightGrey: "#A4A4A4",
  lighterGrey: "#C0C0C0",
  red: "#E02020",
  green: "#3CB800",
  lighterGreen: "#16bf46",
  black: "#000000",
  white: "#FFFFFF",
  lightBlue: "#80C8FF",
  lightRed: "#C42F3C",
  orange: "#f7b500",
  lighterOrange: "#edd38d",
  darkOrange: "#FF8C00",
  franceBlue: "#165DBF",
  marineBlue: "#042a5f",
};
