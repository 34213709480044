import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { toPhoneString } from "./../../utils/phoneUtil";

const Container = styled.div``;

const Title = styled.span`
  display: block;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 125px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    font-size: 17px;
    margin-right: 10px;
    width: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justifycontent: center;
`;

const Name = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

function Shop({ user }) {
  return (
    <Container>
      <Title>MA BOUTIQUE</Title>
      <Row>
        <ProfileImage src={user.imageUrl} />
        <Column style={{ marginLeft: 20 }}>
          <Name>{user.name}</Name>
          <Description>{user.description}</Description>
          <Row style={{ marginTop: 20 }}>
            <i className="fal fa-at" />
            {user.email}
          </Row>
          <Row style={{ marginTop: 20 }}>
            <i className="fal fa-phone-alt" />
            {toPhoneString(user.phoneNumber)}
          </Row>
          <Row style={{ marginTop: 20 }}>
            <i className="fal fa-map-marker-alt" />
            {user.address}, {user.postalCode}, {user.city}
          </Row>
        </Column>
      </Row>
      <Row>
        <Description>
          Montant minimum pour livraison : {user.minShipAmount} €
        </Description>
      </Row>
      <Row>
        <Description>
          Montant minimum pour livraison gratuite : {user.minFreeShipAmount} €
        </Description>
      </Row>
    </Container>
  );
}

const mapState = (state, props) => ({
  user: state.user,
});

const mapActions = {};

export default connect(mapState, mapActions)(Shop);
