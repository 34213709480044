import React from "react";
import styled from "styled-components";
import { dateUtil } from "../../utils/dateUtil";

const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  color: #fff;
  flex-direction: ${(props) => (props.sender === "me" ? "row-reverse" : "row")};
  align-items: center;
  &:first-child {
    margin-top: auto !important;
  }
  &:last-child {
    margin-bottom: 10px;
  }
`;

const MessageDate = styled.div`
  color: black;
  font-size: 10px;
  font-style: italic;
  margin-left: 20px;
  margin-right: 20px;
  color: #aaaaaa;
`;

const MessageText = styled.div`
  max-width: 80%;
  font-size: 13px;
  color: ${(props) => (props.sender === "me" ? "#ffffff" : props.theme.grey)};
  padding: 10px;
  border-radius: 7px;
  background-color: ${(props) =>
    props.sender === "me" ? props.theme.blue : "#ededed"};
`;

function MessageItem({ message, uid }) {
  const sender = message.sentBy === uid ? "me" : "you";
  return (
    <MessageContainer sender={sender}>
      <MessageText sender={sender}>{message.message}</MessageText>
      <MessageDate>
        {dateUtil(message.createdAt).toLocaleDateString("fr-FR")}
        <br />
        {dateUtil(message.createdAt).getHours()}:
        {dateUtil(message.createdAt).getMinutes()}
      </MessageDate>
    </MessageContainer>
  );
}

export default MessageItem;
