export const dateUtil = (date) => {
  const dateZero = new Date(0);
  if (date.seconds) {
    dateZero.setUTCSeconds(date.seconds);
    return new Date(dateZero);
  }
};

export const toTimestamp = (date) => {
  return { seconds: date.valueOf() / 1000, _seconds: date.valueOf() / 1000 };
};

export const twoDigits = (nb) => {
  return parseInt(nb) < 10 ? "0" + nb.toString() : nb.toString();
};

export const mois = {
  1: "Janvier",
  2: "Février",
  3: "Mars",
  4: "Avril",
  5: "Mai",
  6: "Juin",
  7: "Juillet",
  8: "Aout",
  9: "Septembre",
  10: "Octobre",
  11: "Novembre",
  12: "Décembre",
};

export const toMonth = (nb) => {
  let month = nb;
  if (typeof nb === "string") {
    month = parseInt(nb);
  }
  return mois[nb + 1];
};

export const remainingTime = (endDate) => {
  const todaysDate = new Date();
  if (endDate > todaysDate) {
    const remainingTime = endDate.getTime() - todaysDate.getTime();
    const remainingDays = Math.floor(remainingTime / (3600 * 24 * 1000));
    const remainingHours = Math.floor(
      (remainingTime - remainingDays * (3600 * 1000 * 24)) / (1000 * 3600)
    );
    return `${remainingDays}j ${remainingHours}h`;
  }
};
