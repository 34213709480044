import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import PromotionItem from "../components/PromotionItem";
import { openModalAction } from "../../redux/modal/modalActions";

const Container = styled.div`
  padding: 20px;
`;

const Icon = styled.i`
  color: #fff;
  font-size: 22px;
  margin-right: 10px;
`;

const AddPromotionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: ${(props) => props.theme.franceBlue};
  border-radius: 5px;
  border: none;
  outline: 0;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${(props) => props.theme.marineBlue};
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.grey};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PromotionsContainer = styled.div`
  width: 100%;
`;

const NoPromotionsDiv = styled.div`
  width: 80%;
  color: ${(props) => props.theme.marineBlue};
  background-color: #eaeaea;
  text-align: center;
  font-size: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const Promotions = ({ promotions, openModalAction }) => {
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Container>
      <AddPromotionButton
        onClick={() => openModalAction({ modalType: "add-promotion" })}
      >
        <Icon className="fal fa-plus" />
        Ajouter une promotion
      </AddPromotionButton>
      <Text>Mes Promotions</Text>
      {promotions ? (
        <PromotionsContainer>
          {promotions &&
            promotions.map((e) => (
              <PromotionItem key={e.promotionId} promotion={e} />
            ))}
        </PromotionsContainer>
      ) : (
        <NoPromotionsDiv>
          Vous n'avez pas encore créé de promotion
        </NoPromotionsDiv>
      )}
    </Container>
  );
};

// Promotions.propTypes = {
//   props: PropTypes,
// };

const mapStateToProps = (state) => ({
  promotions: state.pro.promotions,
});

const mapDispatchToProps = {
  openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
