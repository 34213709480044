import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { statusOrdersUtil, toOrderNumber } from "../../utils/ordersUtil";
import theme from "../styles/theme";
import OrderProductItem from "../components/OrderProductItem";
import { Link } from "react-router-dom";
import { toPhoneString } from "../../utils/phoneUtil";
import { openModalAction } from "../../redux/modal/modalActions";

const Container = styled.div`
  padding: 20px;
  color: ${(props) => props.theme.grey};
  position: relative;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const Button = styled.button`
  display: inline-flex;
  width: 200px;
  font-size: 18px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  padding: 12px;
  margin-right: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  border: ${(props) => (props.light ? `1px solid ${props.color}` : "none")};
  background-color: ${(props) =>
    props.light ? props.theme.white : props.color};
  color: ${(props) => (props.light ? props.color : props.theme.white)};
  box-shadow: ${(props) =>
    props.light ? "none" : "2px 2px 5px rgba(0, 0, 0, 0.2)"};
  i {
    font-size: 20px;
    margin-right: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const OrderDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const OrderDetailsLeft = styled.div`
  margin-right: 40px;
`;

const OrderBuyer = styled.div`
  font-weight: 600;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
`;

const OrderDetailsRight = styled.div`
  margin-left: 40px;
`;

const OrderStatus = styled.div`
  display: flex;
  flex-direction: "row";
  align-items: center;
  font-style: italic;
`;

const OrderDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  margin-right: 10px;
  background-color: ${(props) => props.color};
`;

const OrderPrice = styled.div`
  font-weight: 500;
  font-size: 17px;
  margin-top: 20px;
`;

const OrderProductsContainer = styled.div`
  margin-top: 40px;
`;

const OrderProductsTitle = styled.div`
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 20px;
`;

const GoBackBtn = styled(Link)`
  padding: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  i {
    color: ${(props) => props.theme.lightRed};
    font-size: 20px;
  }
  &:hover {
    background-color: ${(props) => props.theme.lightRed};
    i {
      color: #ffffff;
    }
  }
`;

const OrderPro = ({ match, order, parentOrders, openModalAction }) => {
  const selectedParentOrder = parentOrders.filter(
    (e) => e.orderId === order.orderId
  )[0];
  const selectedStatus = statusOrdersUtil.filter(
    (e) => e.status === order.status
  )[0];
  console.log(order.products);
  if (order) {
    return (
      <Container>
        <Title>
          <GoBackBtn to="/commandes">
            <i className="fal fa-arrow-left" />
          </GoBackBtn>{" "}
          Commande {toOrderNumber(order.orderNumber)}
        </Title>
        <ButtonsContainer>
          {order.status < 3 && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                openModalAction({
                  modalType: "validate-pro-order",
                  modalContent: order.subOrderId,
                });
              }}
              color={theme.green}
            >
              <i className="fal fa-check" />
              Valider
            </Button>
          )}
          {order.status < 3 && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                openModalAction({
                  modalType: "cancel-pro-order",
                  modalContent: order.subOrderId,
                });
              }}
              color={theme.orange}
            >
              <i className="fal fa-times" />
              Annuler
            </Button>
          )}
          {order.status < 5 && order.status !== 4 && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                openModalAction({
                  modalType: "ship-pro-order",
                  modalContent: order.subOrderId,
                });
              }}
              color={theme.franceBlue}
            >
              <i className="fal fa-truck" />
              Expédier
            </Button>
          )}
          {order.status === 7 && (
            <Button color={theme.lightRed}>
              <i className="fal fa-balance-scale" />
              Voir litige
            </Button>
          )}
          {order.status === 9 && (
            <Button color={theme.orange}>
              <i className="fal fa-undo" />
              Voir le retour
            </Button>
          )}
        </ButtonsContainer>
        <OrderDetails>
          <OrderDetailsLeft>
            <OrderBuyer>Acheteur :</OrderBuyer> <br />
            {`${
              selectedParentOrder.sFirstName
            } ${selectedParentOrder.sLastName.toUpperCase()}`}
            <br />
            {selectedParentOrder.sAddress}
            <br />
            {`${selectedParentOrder.sPostalCode}, ${selectedParentOrder.sCity}`}
            <br />
            <br />
            {selectedParentOrder.sEmailAddress}
            <br />
            {selectedParentOrder.sPhoneNumber &&
              toPhoneString(selectedParentOrder.sPhoneNumber)}
            <br />
            <Button
              onClick={(e) => {
                e.preventDefault();
                openModalAction({
                  modalType: "new-message",
                  modalContent: order.buyerId,
                });
              }}
              light
              color={theme.franceBlue}
            >
              <i className="fal fa-paper-plane" />
              Contacter
            </Button>
          </OrderDetailsLeft>
          <OrderDetailsRight>
            Statut de la commande : <br />
            <br />
            <OrderStatus>
              <OrderDot color={selectedStatus.labelColor} />
              {selectedStatus.text}
            </OrderStatus>
            <OrderPrice>
              Revenus totaux :{" "}
              {(order.transfer.amount / 100).toFixed(2).replace(".", ",")} €
            </OrderPrice>
          </OrderDetailsRight>
        </OrderDetails>

        <OrderProductsContainer>
          <OrderProductsTitle>Produit(s) commandé(s) :</OrderProductsTitle>
          {order.products.map((e) => (
            <OrderProductItem
              product={e}
              key={e.variant ? `${e.productId}/${e.variant}` : `${e.productId}`}
            />
          ))}
        </OrderProductsContainer>
      </Container>
    );
  } else {
    return null;
  }
};

// OrderPro.propTypes = {
//   props: PropTypes,
// };

const mapStateToProps = (state, props) => ({
  order: state.proOrders.ordersList.filter(
    (e) => e.subOrderId === props.match.params.subOrderId
  )[0],
  parentOrders: state.proOrders.parentOrders,
});

const mapDispatchToProps = {
  openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPro);
