import { Form, Formik, Field } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
// import TextInput from "../components/Form/TextInput";
import * as Yup from "yup";
import { signUpUserAction } from "../../redux/user/userActions";
import Loader from "../components/Loader";

const Container = styled.div`
  height: calc(100vh - 70px);
  overflow-y: scroll;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
  color: ${(props) => props.theme.franceBlue};
`;

const FormContainer = styled.div`
  width: 60%;
  display: block;
  margin: auto;
`;

const SubmitButton = styled.button`
  color: #ffffff;
  padding: 20px;
  width: 100%;
  border: none;
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 500;
  background-color: ${(props) => props.theme.franceBlue};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-right: 20px;
    color: #ffffff;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.lightRed};
  font-style: italic;
  font-size: 10px;
  margin-top: -20px;
`;

const TextInput = styled(Field)`
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.lightRed : "#dfdfdf")};
  border-radius: 5px;
`;

const DownloadLogos = styled.img`
  display: block;
  margin: 10px auto 10px auto;
  width: 200px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SignUp = ({ signUpUserAction, userLoading, userCreated }) => {
  if (!userCreated) {
    return (
      <Container>
        <FormContainer>
          <Title>Création de compte</Title>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              address: "",
              postalCode: "",
              city: "",
              phoneNumber: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Adresse e-mail requise"),
              firstName: Yup.string().required("Prénom requis"),
              lastName: Yup.string().required("Nom requis"),
              phoneNumber: Yup.string()
                .min(10, "Trop court")
                .max(10, "Trop long")
                .required("Numéro de téléphone requis"),
              address: Yup.string().required("Adresse requise"),
              city: Yup.string().required("Ville requise"),
              postalCode: Yup.string()
                .min(5, "Code postal invalide")
                .max(5, "Code postal invalide")
                .required("Code postal requis"),
              password: Yup.string().required("Mot de passe requis"),
              confirmPassword: Yup.string().test(
                "passwords-match",
                "Les mots de passe doivent correspondre",
                function (value) {
                  return this.parent.password === value;
                }
              ),
            })}
            onSubmit={async (values) => {
              signUpUserAction(values);
            }}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <TextInput name="firstName" placeholder="Prénom *" />
                {touched.firstName && errors.firstName ? (
                  <ErrorText>{errors.firstName}</ErrorText>
                ) : null}

                <TextInput placeholder="Nom de famille *" name="lastName" />
                {touched.lastName && errors.lastName ? (
                  <ErrorText>{errors.lastName}</ErrorText>
                ) : null}

                <TextInput
                  placeholder="Numéro de téléphone *"
                  name="phoneNumber"
                />
                {touched.phoneNumber && errors.phoneNumber ? (
                  <ErrorText>{errors.phoneNumber}</ErrorText>
                ) : null}

                <TextInput placeholder="Adresse postale *" name="address" />
                {touched.address && errors.address ? (
                  <ErrorText>{errors.address}</ErrorText>
                ) : null}

                <TextInput placeholder="Code postal *" name="postalCode" />
                {touched.postalCode && errors.postalCode ? (
                  <ErrorText>{errors.postalCode}</ErrorText>
                ) : null}

                <TextInput placeholder="Ville *" name="city" />
                {touched.city && errors.city ? (
                  <ErrorText>{errors.city}</ErrorText>
                ) : null}

                <TextInput placeholder="Adresse E-mail *" name="email" />
                {touched.email && errors.email ? (
                  <ErrorText>{errors.email}</ErrorText>
                ) : null}

                <TextInput
                  placeholder="Mot de passe *"
                  type="password"
                  name="password"
                />
                {touched.password && errors.password ? (
                  <ErrorText>{errors.password}</ErrorText>
                ) : null}

                <TextInput
                  placeholder="Confirmation de mot de passe *"
                  type="password"
                  name="confirmPassword"
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <ErrorText>{errors.confirmPassword}</ErrorText>
                ) : null}

                <SubmitButton onClick={handleSubmit}>
                  {userLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <i className="far fa-check" />
                      CR&Eacute;ER UN COMPTE
                    </>
                  )}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <Title>Félicitations ! 🎉</Title>
        <Message>
          Tu es maintenant inscrit à La Chariotte. Pour accéder à la plateforme,
          rendez-vous sur notre application mobile.
        </Message>
        <a
          id="button"
          href="https://apps.apple.com/fr/app/la-chariotte/id1544658551"
        >
          <DownloadLogos
            src="https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/apple-store-button.png?alt=media"
            target="_blank"
            alt="Télécharger pour iPhone"
          />
        </a>
        <a
          id="button"
          href="https://play.google.com/store/apps/details?id=com.lachariottenative"
        >
          <DownloadLogos
            target="_blank"
            src="https://firebasestorage.googleapis.com/v0/b/la-chariotte.appspot.com/o/google-play-button.png?alt=media"
            alt="Télécharger pour Android"
          />
        </a>
      </Container>
    );
  }
};

const mapStateToProps = (state) => ({
  userLoading: state.user.loading,
  userCreated: state.user.userCreated,
});

const mapDispatchToProps = {
  signUpUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
