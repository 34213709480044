import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModalAction } from "../../redux/modal/modalActions";
import { addToastrAction } from "../../redux/toastr/toastrActions";
import { updateUserAction } from "../../redux/user/userActions";
import TextInput from "../components/Form/TextInput";

const Container = styled.div`
  height: auto;
`;

const Title = styled.div`
  margin-top: 30px;
  font-size: 17px;
  font-weight: 700;
`;
const SubTitle = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 500;
  color: ${(props) => props.theme.lightRed};
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.franceBlue};
  color: #ffffff;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  font-size: 17px;
  margin-top: 10px;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
`;

function ChangeEmailModal({
  updatedUser,
  addToastrAction,
  updateUserAction,
  closeModalAction,
}) {
  return (
    <Container>
      <Title>
        Vous avez modifié votre adresse email, veuillez confirmer en saisissant
        votre nouvelle adresse email
      </Title>
      <SubTitle>
        Attention, ceci modifie également l'adresse email avec laquelle vous
        vous connectez à votre compte
      </SubTitle>
      <Formik
        initialValues={{
          changeEmail: "",
        }}
        onSubmit={(values) => {
          if (values.changeEmail === updatedUser.email) {
            updateUserAction({
              ...updatedUser,
              email: values.changeEmail,
            });
            closeModalAction();
          } else {
            addToastrAction({
              type: "error",
              title: "Erreur",
              message:
                "L'adresse email entrée ne correspond pas avec celle modifiée précédemment, veuillez réessayer",
            });
          }
        }}
      >
        {({ handleChange, values, handleSubmit }) => (
          <Form>
            <TextInput
              value={values.changeEmail}
              onChange={handleChange}
              name="changeEmail"
              label="Nouvelle adresse e-mail"
            />
            <SubmitButton type="submit">
              <i className="fal fa-check" />
              Confirmer l'adresse e-mail
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const mapState = (state, props) => ({});

const mapActions = {
  addToastrAction,
  updateUserAction,
  closeModalAction,
};

export default connect(mapState, mapActions)(ChangeEmailModal);
