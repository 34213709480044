import { createReducer } from "./../../utils/reducerUtil";
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  GO_BACK_MODAL,
  ADD_STACK_MODAL,
} from "./modalConstants";

const initialState = {
  open: false,
  modalTitle: null, // sort, filter, product, order
  modalType: null,
  modalContent: null, // sorting redux, filters redux, product (w/ productId), order (w/ orderId)
  confirmAction: null, // submit action if required (on modal close)
  modalStack: [],
  navigation: null,
};

export const openModal = (state, payload) => {
  return { ...state, open: true, ...payload };
};

export const closeModal = (state, payload) => {
  return {
    open: false,
    modalType: null,
    modalContent: null,
    modalTitle: null,
    modalStack: [],
  };
};

export const goBackModal = (state, payload) => {
  return {
    open: true,
    ...state.modalStack[state.modalStack.length - 1],
    modalStack: state.modalStack.slice(state.modalStack.length),
  };
};
export const addStackModal = (state, payload) => {
  return {
    ...state,
    open: true,
    modalTitle: payload.modalTitle,
    modalContent: payload.modalContent,
    modalType: payload.modalType,
    modalStack: [
      ...state.modalStack,
      {
        modalTitle: state.modalTitle,
        modalContent: state.modalContent,
        modalType: state.modalType,
      },
    ],
  };
};

export default createReducer(initialState, {
  [OPEN_MODAL]: openModal,
  [CLOSE_MODAL]: closeModal,
  [GO_BACK_MODAL]: goBackModal,
  [ADD_STACK_MODAL]: addStackModal,
});
